import styles from "../../css/unit5/collisions.module.css"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Canvas from "./canvas";

function setAngle(define, angle=null) {
	const vec1 = document.getElementById(define+"_angle_v").value
	const vec2 = document.getElementById(define+"_angle_h").value
	if(angle === null)
		angle = Number(document.getElementById(define+"_angle").value)

	let return_angle = 0;
	if(vec1 === "N") {
		if(vec2 === "E")
			return_angle = 90-angle;
		else
			return_angle = 90+angle;
	} else {
		if(vec2 === "E")
			return_angle = 270+angle;
		else
			return_angle = 270-angle;
	}
	if(return_angle%360 < 0) {
		return_angle = 360 + return_angle%360
	}
	return Number(return_angle%360);
}

function setInputAngle(define, angle=null) {
	if(angle < 0) {
		angle += 360
	}
	var vec1 = document.getElementById(define+"_angle_v")
	var vec2 = document.getElementById(define+"_angle_h")

	if(angle >= 0 && angle <= 180) {
		vec1.value = "N"
	} else {
		vec1.value = "S"
		angle -= 180
	}

	if((angle >= 0 && angle <= 90) || (angle >= 270 && angle <= 360)) {
		vec2.value = "E"
		angle = 90-angle
	} else {
		vec2.value = "W"
		angle = angle-90
	}
	document.getElementById(define+"_angle").setAttribute("placeholder", angle)
}

export default function Unit5Collisions() {
	const [objects, setObjects] = useState([
		
	]);

	const [values, setValues] = useState({
		m1: 1,
		m2: 1,
		v1i: 0,
		v2i: 0,
		v1f: 0,
		v2f: 0,
		v1iangle: 90,
		v1fangle: 90,
		v2iangle: 90,
		v2fangle: 90,
		elastic: "true",
	})

	useEffect(() => {
		if(values.elastic === "true") {
			const v1ix = Number(values.v1i)*Math.cos(values.v1iangle*Math.PI/180).toFixed(3);
			const v1iy = Number(values.v1i)*Math.sin(values.v1iangle*Math.PI/180).toFixed(3);
			const v1fx = Number(values.v1f)*Math.cos(values.v1fangle*Math.PI/180).toFixed(3);
			const v1fy = Number(values.v1f)*Math.sin(values.v1fangle*Math.PI/180).toFixed(3);
			const v2ix = Number(values.v2i)*Math.cos(values.v2iangle*Math.PI/180).toFixed(3);
			const v2iy = Number(values.v2i)*Math.sin(values.v2iangle*Math.PI/180).toFixed(3);
			const v2fx = Number(values.v2f)*Math.cos(values.v2fangle*Math.PI/180).toFixed(3);
			const v2fy = Number(values.v2f)*Math.sin(values.v2fangle*Math.PI/180).toFixed(3);

			const v1i_val = document.getElementById("v1i_value").value
			const v1f_val = document.getElementById("v1f_value").value
			const v2i_val = document.getElementById("v2i_value").value
			const v2f_val = document.getElementById("v2f_value").value

			const m1 = Number(values.m1);
			const m2 = Number(values.m2);

			document.getElementById("v1i_value").setAttribute("placeholder", "")
			document.getElementById("v1f_value").setAttribute("placeholder", "")
			document.getElementById("v2i_value").setAttribute("placeholder", "")
			document.getElementById("v2f_value").setAttribute("placeholder", "")
			document.getElementById("v1_angle").setAttribute("placeholder", "")
			document.getElementById("v1f_angle").setAttribute("placeholder", "")
			document.getElementById("v2i_angle").setAttribute("placeholder", "")
			document.getElementById("v2f_angle").setAttribute("placeholder", "")

			if(v1i_val !== "") {
				if(v2i_val !== "") {
					const cv1fx = ((2*m2*v2ix)+(v1ix*(m1-m2)))/(m1+m2)
					const cv1fy = ((2*m2*v2iy)+(v1iy*(m1-m2)))/(m1+m2)
					const cv2fx = ((2*m1*v1ix)+(v2ix*(m2-m1)))/(m1+m2)
					const cv2fy = ((2*m1*v1iy)+(v2iy*(m2-m1)))/(m1+m2)
					document.getElementById("v1f_value").setAttribute("placeholder", Math.sqrt(Math.pow(cv1fx, 2)+Math.pow(cv1fy, 2)).toFixed(2))
					document.getElementById("v2f_value").setAttribute("placeholder", Math.sqrt(Math.pow(cv2fx, 2)+Math.pow(cv2fy, 2)).toFixed(2))
					setInputAngle("v1f", (Math.atan2(cv1fy, cv1fx))*180/Math.PI);
					setInputAngle("v2f", (Math.atan2(cv2fy, cv2fx))*180/Math.PI);
				} else if(v1f_val !== "") {
					const cv2ix = ((v1ix*(m2-m1))+(v1fx*(m1+m2)))/(2*m2)
					const cv2iy = ((v1iy*(m2-m1))+(v1fy*(m1+m2)))/(2*m2)
					const cv2fx = ((v1ix*(m1+m2))-(v1fx*(m1-m2)))/(2*m2)
					const cv2fy = ((v1iy*(m1+m2))-(v1fy*(m1-m2)))/(2*m2)
					document.getElementById("v2i_value").setAttribute("placeholder", Math.sqrt(Math.pow(cv2ix, 2)+Math.pow(cv2iy, 2)).toFixed(2))
					document.getElementById("v2f_value").setAttribute("placeholder", Math.sqrt(Math.pow(cv2fx, 2)+Math.pow(cv2fy, 2)).toFixed(2))
					setInputAngle("v2i", (Math.atan2(cv2iy, cv2ix))*180/Math.PI);
					setInputAngle("v2f", (Math.atan2(cv2fy, cv2fx))*180/Math.PI);
				} else if(v2f_val !== "") {
					const cv1fx = ((v1ix*(m1+m2))-(2*m2*v2fx))/Math.max((m1-m2), 0.01)
					const cv1fy = ((v1iy*(m1+m2))-(2*m2*v2fy))/Math.max((m1-m2), 0.01)
					const cv2ix = ((v1ix*(m2-m1))+(cv1fx*(m1+m2)))/(2*m2)
					const cv2iy = ((v1iy*(m2-m1))+(cv1fy*(m1+m2)))/(2*m2)
					document.getElementById("v1f_value").setAttribute("placeholder", Math.sqrt(Math.pow(cv1fx, 2)+Math.pow(cv1fy, 2)).toFixed(2))
					document.getElementById("v2i_value").setAttribute("placeholder", Math.sqrt(Math.pow(cv2ix, 2)+Math.pow(cv2iy, 2)).toFixed(2))
					setInputAngle("v1f", (Math.atan2(cv1fy, cv1fx))*180/Math.PI);
					setInputAngle("v2i", (Math.atan2(cv2iy, cv2ix))*180/Math.PI);
				}
			} else if(v2i_val !== "") {
				if(v1f_val !== "") {
					const cv2fx = ((v2ix*(m1+m2))-(2*m1*v1fx))/Math.max((m2-m1), 0.01)
					const cv2fy = ((v2iy*(m1+m2))-(2*m1*v1fy))/Math.max((m2-m1), 0.01)
					const cv1ix = v2ix+cv2fx-v1fx
					const cv1iy = v2iy+cv2fy-v1fy
					document.getElementById("v1i_value").setAttribute("placeholder", Math.sqrt(Math.pow(cv1ix, 2)+Math.pow(cv1iy, 2)).toFixed(2))
					document.getElementById("v2f_value").setAttribute("placeholder", Math.sqrt(Math.pow(cv2fx, 2)+Math.pow(cv2fy, 2)).toFixed(2))
					setInputAngle("v1", (Math.atan2(cv1iy, cv1ix))*180/Math.PI);
					setInputAngle("v2f", (Math.atan2(cv2fy, cv2fx))*180/Math.PI);
				} else if(v2f_val !== "") {
					const cv1fx = ((v2ix*(m1+m2))-(v2fx*(m2-m1)))/(2*m1)
					const cv1fy = ((v2iy*(m1+m2))-(v2fy*(m2-m1)))/(2*m1)
					const cv1ix = v2ix+v2fx-cv1fx
					const cv1iy = v2iy+v2fy-cv1fy
					document.getElementById("v1i_value").setAttribute("placeholder", Math.sqrt(Math.pow(cv1ix, 2)+Math.pow(cv1iy, 2)).toFixed(2))
					document.getElementById("v1f_value").setAttribute("placeholder", Math.sqrt(Math.pow(cv1fx, 2)+Math.pow(cv1fy, 2)).toFixed(2))
					setInputAngle("v1", (Math.atan2(cv1iy, cv1ix))*180/Math.PI);
					setInputAngle("v1f", (Math.atan2(cv1fy, cv1fx))*180/Math.PI);
				}
			} else if(v1f_val !== "") {
				if(v2f_val !== "") {
					const cv1ix = ((2*m2*v2fx)+(v1fx*(m1-m2)))/(m1+m2)
					const cv1iy = ((2*m2*v2fy)+(v1fy*(m1-m2)))/(m1+m2)
					const cv2ix = ((2*m1*v1fx)+(v2fx*(m2-m1)))/(m1+m2)
					const cv2iy = ((2*m1*v1fy)+(v2fy*(m2-m1)))/(m1+m2)
					document.getElementById("v1i_value").setAttribute("placeholder", Math.sqrt(Math.pow(cv1ix, 2)+Math.pow(cv1iy, 2)))
					document.getElementById("v2i_value").setAttribute("placeholder", Math.sqrt(Math.pow(cv2ix, 2)+Math.pow(cv2iy, 2)))
					setInputAngle("v1", (Math.atan2(cv1iy, cv1ix))*180/Math.PI);
					setInputAngle("v2i", (Math.atan2(cv2iy, cv2ix))*180/Math.PI);
				}
			}
		} else {
			const v1x = Number(values.v1i)*Math.cos(values.v1iangle*Math.PI/180).toFixed(3);
			const v1y = Number(values.v1i)*Math.sin(values.v1iangle*Math.PI/180).toFixed(3);
			const v2x = Number(values.v2i)*Math.cos(values.v2iangle*Math.PI/180).toFixed(3);
			const v2y = Number(values.v2i)*Math.sin(values.v2iangle*Math.PI/180).toFixed(3);
			const vtx = Number(values.v2f)*Math.cos(values.v2fangle*Math.PI/180).toFixed(3);
			const vty = Number(values.v2f)*Math.sin(values.v2fangle*Math.PI/180).toFixed(3);

			const v1_val = document.getElementById("v1i_value").value
			const v2_val = document.getElementById("v2i_value").value
			const vt_val = document.getElementById("vt_value").value

			document.getElementById("v1i_value").setAttribute("placeholder", "")
			document.getElementById("v2i_value").setAttribute("placeholder", "")
			document.getElementById("vt_value").setAttribute("placeholder", "")
			document.getElementById("v1_angle").setAttribute("placeholder", "")
			document.getElementById("v2i_angle").setAttribute("placeholder", "")
			document.getElementById("vt_angle").setAttribute("placeholder", "")

			if(v1_val !== "") {
				if(v2_val !== "") {
					const vtxf = ((values.m1*v1x) + (values.m2*v2x)) / (Number(values.m1)+Number(values.m2))
					const vtyf = ((values.m1*v1y) + (values.m2*v2y)) / (Number(values.m1)+Number(values.m2))
					document.getElementById("vt_value").setAttribute("placeholder", (Math.sqrt((vtxf*vtxf)+(vtyf*vtyf))).toFixed(3))
					setInputAngle("vt", (Math.atan2(vtyf, vtxf))*180/Math.PI);
				} else if(vt_val !== "") {
					const vtxf = (((Number(values.m1)+Number(values.m2))*vtx) - (values.m1*v1x)) / Number(values.m2)
					const vtyf = (((Number(values.m1)+Number(values.m2))*vty) - (values.m1*v1y)) / Number(values.m2)
					document.getElementById("v2i_value").setAttribute("placeholder", (Math.sqrt((vtxf*vtxf)+(vtyf*vtyf))).toFixed(3))
					setInputAngle("v2i", (Math.atan2(vtyf, vtxf))*180/Math.PI);
				}
			} else if (v2_val !== "") {
				if(vt_val !== "") {
					const vtxf = (((Number(values.m1)+Number(values.m2))*vtx) - (values.m2*v2x)) / Number(values.m1)
					const vtyf = (((Number(values.m1)+Number(values.m2))*vty) - (values.m2*v2y)) / Number(values.m1)
					document.getElementById("v1i_value").setAttribute("placeholder", (Math.sqrt((vtxf*vtxf)+(vtyf*vtyf))).toFixed(3))
					setInputAngle("v1", (Math.atan2(vtyf, vtxf))*180/Math.PI);
				}
			}
		}
	}, [values])

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<div className={styles.dropdown}>
					<p className={styles.title}>Left Side</p>
					<div className={styles.holder}>
						<p className={styles.value}>m<sub>1</sub></p>
						<input value={values.m1} className={styles.input} onChange={event=>{setValues({...values, m1: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>v<sub>1</sub></p>
						<input id="v1i_value" className={styles.input} onChange={event=>{setValues({...values, v1i: event.target.value})}}></input>
						<p className={styles.unitsAngle}>m/s</p>
						<select id="v1_angle_v" onChange={event=>{setValues({...values, v1iangle: setAngle("v1")})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input placeholder="0" id="v1_angle" className={styles.degrees} onChange={event=>{setValues({...values, v1iangle: setAngle("v1")})}}></input>
						<select id="v1_angle_h" onChange={event=>{setValues({...values, v1iangle: setAngle("v1")})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
					{values.elastic === "true" ? 
					<div className={styles.holder}>
						<p className={styles.value}>v<sub>1</sub>'</p>
						<input id="v1f_value" className={styles.input} onChange={event=>{setValues({...values, v1f: event.target.value})}}></input>
						<p className={styles.unitsAngle}>m/s</p>
						<select id="v1f_angle_v" onChange={event=>{setValues({...values, v1fangle: setAngle("v1f")})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input placeholder="0" id="v1f_angle" className={styles.degrees} onChange={event=>{setValues({...values, v1fangle: setAngle("v1f")})}}></input>
						<select id="v1f_angle_h" onChange={event=>{setValues({...values, v1fangle: setAngle("v1f")})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
					: <></> }
				</div>
				<div className={styles.dropdown}>
					<p className={styles.title}>Right Side</p>
					<div className={styles.holder}>
						<p className={styles.value}>m<sub>2</sub></p>
						<input value={values.m2} className={styles.input} onChange={event=>{setValues({...values, m2: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>v<sub>2</sub></p>
						<input id="v2i_value" className={styles.input} onChange={event=>{setValues({...values, v2i: event.target.value})}}></input>
						<p className={styles.unitsAngle}>m/s</p>
						<select id="v2i_angle_v" onChange={event=>{setValues({...values, v2iangle: setAngle("v2i")})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input placeholder="0" id="v2i_angle" className={styles.degrees} onChange={event=>{setValues({...values, v2iangle: setAngle("v2i")})}}></input>
						<select id="v2i_angle_h" onChange={event=>{setValues({...values, v2iangle: setAngle("v2i")})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
					{values.elastic === "true" ? 
					<div className={styles.holder}>
						<p className={styles.value}>v<sub>2</sub>'</p>
						<input id="v2f_value" className={styles.input} onChange={event=>{setValues({...values, v2f: event.target.value})}}></input>
						<p className={styles.unitsAngle}>m/s</p>
						<select id="v2f_angle_v" onChange={event=>{setValues({...values, v2fangle: setAngle("v2f")})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input placeholder="0" id="v2f_angle" className={styles.degrees} onChange={event=>{setValues({...values, v2fangle: setAngle("v2f")})}}></input>
						<select id="v2f_angle_h" onChange={event=>{setValues({...values, v2fangle: setAngle("v2f")})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
					:
					<div className={styles.holder}>
						<p className={styles.value}>v<sub>T</sub></p>
						<input id="vt_value" className={styles.input} onChange={event=>{setValues({...values, v2f: event.target.value})}}></input>
						<p className={styles.unitsAngle}>m/s</p>
						<select id="vt_angle_v" onChange={event=>{setValues({...values, v2fangle: setAngle("vt")})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input placeholder="0" id="vt_angle" className={styles.degrees} onChange={event=>{setValues({...values, v2fangle: setAngle("vt")})}}></input>
						<select id="vt_angle_h" onChange={event=>{setValues({...values, v2fangle: setAngle("vt")})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
					}
					<select onChange={event=>{setValues({...values, elastic: event.target.value})}}>
						<option value={true}>Elastic</option>
						<option value={false}>Inelastic</option>
					</select>
				</div>
			</div>
			<Canvas 
			verticesList={objects}
			// vectors={vectors}
			/>
		</div>
	);
}

