import React, { useEffect, useState } from "react";
import styles from "../../css/unit2/simulator.module.css"
import { Link } from "react-router-dom";
import ForcesCanvas from "./ForcesCanvas";

// normalText0123₀₁₂₃₄₅₆₇₈₉

function setAngle(define, angle=null) {
	const vec1 = document.getElementById(define+"_angle_v").value
	const vec2 = document.getElementById(define+"_angle_h").value
	if(angle === null)
		angle = Number(document.getElementById(define+"_angle").value)

	let return_angle = 0;
	if(vec1 === "N") {
		if(vec2 === "E")
			return_angle = 90-angle;
		else
			return_angle = 90+angle;
	} else {
		if(vec2 === "E")
			return_angle = 270+angle;
		else
			return_angle = 270-angle;
	}
	if(return_angle%360 < 0) {
		return_angle = 360 + return_angle%360
	}
	return Number(return_angle%360);
}

export default function Unit2Simulator() {
	const [objects, setObjects] = useState([
		{
			type: "polygon",
			polygon: [
				{
					x: -5,
					y: 0,
				},
				{
					x: 0,
					y: 5,
				},
				{
					x: 0,
					y: 0,
				},
				{
					x: -5,
					y: 0,
				}
			],
			colorStroke: "#000000",
			colorFill: '#cafcc7'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: 5,
					y: 0,
				},
				{
					x: 0,
					y: 5,
				},
				{
					x: 0,
					y: 0,
				}
			],
			colorStroke: "#000000",
			colorFill: '#cafcc7'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -3,
					y: 2,
				},
				{
					x: -2,
					y: 3,
				},
				{
					x: -3,
					y: 4,
				},
				{
					x: -4,
					y: 3,
				}
			],
			colorStroke: "#000000",
			colorFill: '#0000'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -5,
					y: 0,
				},
				{
					x: -5.5,
					y: -0.5,
				},
				{
					x: -6.5,
					y: 0.5,
				},
				{
				x: -6,
				y: 1,
				}
			],
			colorStroke: "#000000",
			colorFill: '#fc5858'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -5.75,
					y: 0.75,
				},
				{
					x: -4.75,
					y: 0.75,
				},
				{
					x: -4.75,
					y: 1.75,
				},
				{
					x: -3.75,
					y: 1.75,
				},
			],
			colorStroke: "#000000",
			colorFill: '#0000',
			completePath: false,
			lineWidth: 4,
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -3.5,
					y: 1.5,
				},
				{
					x: -3,
					y: 2,
				},
				{
					x: -4,
					y: 3,
				},
				{
					x: -4.5,
					y: 2.5,
				},
			],
			colorStroke: "#000000",
			colorFill: '#1a1a28',
		},
		{
			type: "polygon",
			polygon: [
				{
					x: 5,
					y: 0,
				},
				{
					x: 0,
					y: 5,
				},
				{
					x: 0,
					y: 0,
				}
			],
			colorStroke: "#000000",
			colorFill: '#cafcc7'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: 3,
					y: 2,
				},
				{
					x: 2,
					y: 3,
				},
				{
					x: 3,
					y: 4,
				},
				{
					x: 4,
					y: 3,
				}
			],
			colorStroke: "#000000",
			colorFill: '#0000'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -2.5,
					y: 3.5,
				},
				{
					x: -0.1,
					y: 5.89,
				},
				{
					x: -0.05,
					y: 5.92,
				},
				{
					x: 0,
					y: 5.93,
				},
				{
					x: 0.05,
					y: 5.92,
				},
				{
					x: 0.1,
					y: 5.89,
				},
				{
					x: 2.5,
					y: 3.5,
				},
			],
			colorStroke: "#000000",
			colorFill: '#0000',
			completePath: false,
		},
		{
			type: "point",
			coordinates: [
				{
					x: 0,
					y: 5.76,
				},
			],
			color: "#000000",
		},
		{
			type: "text",
			text: "θ₂",
			x: 4.3,
			y: 0.25,
			size: 0.5
		},
		{
			type: "text",
			text: "θ₁",
			x: -4.3,
			y: 0.25,
			size: 0.5
		},
		{
			type: "text",
			text: "m₁",
			x: -3,
			y: 3,
			size: 0.5
		},
		{
			type: "text",
			text: "m₂",
			x: 3,
			y: 3,
			size: 0.5
		},
	]);

	const [values, setValues] = useState({
		m1: "1",
		angle1: "0",
		mus1: "0",
		muk1: "0",
		fa1: "0",
		faangle1: "",
		k1: "",
		x1: "",
		m2: "0",
		angle2: "0",
		mu2: "0",
		g: "9.81",
		afor: "0",
		aforangle: "0",
	})

	const [vectors, setVectors] = useState(
		{
			fnet1: {
				x: 0,
				y: 0,
			},
			fnet2: {
				x: 0,
				y: 0,
			},
			m1: {
				gravity: {
					notation: "g",
					value: values.m1*values.g,
					angle: 270,
				},
				normal: {
					notation: "N",
					value: 0,
					angle: Number(values.angle1)+90,
				},
				applied: {
					notation: "A",
					value: Number(values.fa1),
					angle: Number(values.faangle1),
				},
				friction: {
					notation: "F",
					value: 0,
					angle: Number(values.angle1),
				},
				spring: {
					notation: "s",
					value: Number(values.k1)*Number(values.x1),
					angle: Number(values.angle1),
				},
				tension: {
					notation: "T",
					value: (values.m2*values.g)*Math.sin(values.angle2*Math.PI/180),
					angle: Number(values.angle1),
				},
				pseudoforce: {
					notation: "P",
					value: values.m1*values.afor,
					angle: Number(values.aforangle),
				},
			},
			m2: {
				gravity: {
					notation: "g",
					value: values.m2*values.g,
					angle: 270,
				},
				normal: {
					notation: "N",
					value: 0,
					angle: 0,
				},
				tension: {
					notation: "T",
					value: 0,
					angle: 0,
				},
				friction: {
					notation: "F",
					value: 0,
					angle: 0,
				},
			}
		}
	)

	useEffect(() => {
		const gx = -Math.round((values.m1*values.g)*Math.sin(values.angle1*Math.PI/180)*1000)/1000;
		const gy = -Math.round((values.m1*values.g)*Math.cos(values.angle1*Math.PI/180)*1000)/1000;

		const ax = Math.round((values.fa1)*Math.cos((values.faangle1-values.angle1)*Math.PI/180)*1000)/1000;
		const ay = Math.round((values.fa1)*Math.sin((values.faangle1-values.angle1)*Math.PI/180)*1000)/1000;

		const sx = Math.round((Number(values.k1)*Number(values.x1))*1000)/1000

		const px = Math.round((values.m1*values.afor)*Math.cos((values.aforangle-values.angle1)*Math.PI/180)*1000)/1000
		const py = Math.round((values.m1*values.afor)*Math.sin((values.aforangle-values.angle1)*Math.PI/180)*1000)/1000

		var fnet1x = gx + ax + sx + px;
		const fnet1y = gy + ay + py;

		const g2x = Math.round((values.m2*values.g)*Math.sin(values.angle2*Math.PI/180)*1000)/1000;
		const g2y = -Math.round((values.m2*values.g)*Math.cos(values.angle2*Math.PI/180)*1000)/1000;
		const p2x = Math.round((values.m2*values.afor)*Math.cos((values.aforangle-values.angle2)*Math.PI/180)*1000)/1000
		const p2y = Math.round((values.m2*values.afor)*Math.sin((values.aforangle-values.angle2)*Math.PI/180)*1000)/1000

		var fn = 0
		var ff1 = 0;
		var ff1angle = 0;
		var fn2 = 0
		var ft2 = 0
		var ff2 = 0
		var ff2angle = 0
		
		if(fnet1y < 0) {
			fn = -fnet1y
		}

		if(fnet1x < 0) {
			ft2 = -fnet1x
		}

		var fnet2x = g2x + p2x - ft2;
		const fnet2y = g2y - p2y;

		if(fnet2y <= 0) {
			fn2 = -fnet2y
		}

		var tx = Math.round((((values.m2*values.g)*Math.sin(values.angle2*Math.PI/180)))*1000)/1000

		if(fnet2x < 0) {
			ff2 = Math.min(-fnet2x, values.mu2*fn2)
			ff2angle = 360-values.angle2
			tx -= ff2
		} else if (fnet2x > 0) {
			ff2 = -Math.min(fnet2x, values.mu2*fn2)
			ff2angle = 180-values.angle2
			tx += ff2
		}

		if(tx < 0) {
			tx = 0
		}

		fnet1x += tx;

		fnet2x += ft2;

		if(fnet1x < 0) {
			ff1 = Math.min(-fnet1x, Number(values.mus1)*fn)
			ff1angle = 90-values.angle1
			ft2 -= ff1
		} else if (fnet1x > 0) {
			ff1 = -Math.min(fnet1x, Number(values.mus1)*fn)
			ff1angle = 270-values.angle1
			ft2 += ff1
		}

		fnet2x -= ft2

		setVectors({
			...vectors,
			fnet1: {
				x: fnet1x+ff1,
				y: fnet1y+fn,
			},
			fnet2: {
				x: fnet2x+ff2,
				y: fnet2y+fn2,
			},
			m1: {
				gravity: {
					notation: "g",
					value: values.m1*values.g,
					angle: 270,
				},
				normal: {
					notation: "N",
					value: fn,
					angle: Number(values.angle1)+90,
				},
				applied: {
					notation: "A",
					value: Number(values.fa1),
					angle: Number(values.faangle1),
				},
				friction: {
					notation: "F",
					value: Math.abs(ff1),
					angle: ff1angle,
				},
				spring: {
					notation: "s",
					value: Number(values.k1)*Number(values.x1),
					angle: Number(values.angle1),
				},
				tension: {
					notation: "T",
					value: tx,
					angle: Number(values.angle1),
				},
				pseudoforce: {
					notation: "P",
					value: values.m1*values.afor,
					angle: Number(values.aforangle),
				},
			},
			m2: {
				gravity: {
					notation: "g",
					value: values.m2*values.g,
					angle: 270,
				},
				normal: {
					notation: "N",
					value: fn2,
					angle: 90-Number(values.angle2),
				},
				tension: {
					notation: "T",
					value: ft2,
					angle: 180-Number(values.angle2),
				},
				friction: {
					notation: "F",
					value: ff2,
					angle: ff2angle,
				},
				pseudoforce: {
					notation: "P",
					value: values.m2*values.afor,
					angle: Number(values.aforangle),
				},
			},
			tensionstring: {
				value: values.m2*(values.g-(Math.sqrt(Math.pow(fnet1x+ff1, 2) + Math.pow(fnet1y+fn, 2)))/(Number(values.m1)+Number(values.m2))),
			},
		})
	}, [values])

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<div className={styles.dropdown}>
					<p className={styles.title}>Left Side</p>
					<div className={styles.holder}>
						<p className={styles.value}>m<sub>1</sub></p>
						<input value={values.m1} className={styles.input} onChange={event=>{setValues({...values, m1: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>angle</p>
						<input value={values.angle1} className={styles.input} onChange={event=>{setValues({...values, angle1: event.target.value})}}></input>
						<p className={styles.units}>°</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>μ<sub>s</sub></p>
						<input value={values.mus1} className={styles.input} onChange={event=>{setValues({...values, mus1: event.target.value})}}></input>
						<p className={styles.units}></p>
					</div>
					{/* <div className={styles.holder}>
						<p className={styles.value}>μ<sub>k</sub></p>
						<input value={values.mu1} className={styles.input} onChange={event=>{setValues({...values, mu1: event.target.value})}}></input>
						<p className={styles.units}></p>
					</div> */}
					<div className={styles.holder}>
						<p className={styles.value}>F<sub>A</sub></p>
						<input value={values.fa1} className={styles.inputAngle} onChange={event=>{setValues({...values, fa1: event.target.value, faangle1: setAngle("fa")})}}></input>
						<p className={styles.unitsAngle}>N</p>
						<select id="fa_angle_v" onChange={event=>{setValues({...values, faangle1: setAngle("fa")})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input placeholder="0" id="fa_angle" className={styles.degrees} onChange={event=>{setValues({...values, faangle1: setAngle("fa")})}}></input>
						<select id="fa_angle_h" onChange={event=>{setValues({...values, faangle1: setAngle("fa")})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>k</p>
						<input value={values.k1} className={styles.input} onChange={event=>{setValues({...values, k1: event.target.value})}}></input>
						<p className={styles.units}>N/m</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>x</p>
						<input value={values.x1} className={styles.input} onChange={event=>{setValues({...values, x1: event.target.value})}}></input>
						<p className={styles.units}>m</p>
					</div>
				</div>
				<div className={styles.dropdown}>
					<p className={styles.title}>Right Side</p>
					<div className={styles.holder}>
						<p className={styles.value}>m<sub>2</sub></p>
						<input value={values.m2} className={styles.input} onChange={event=>{setValues({...values, m2: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>angle</p>
						<input value={values.angle2} className={styles.input} onChange={event=>{setValues({...values, angle2: Number(event.target.value)})}}></input>
						<p className={styles.units}>°</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>μ<sub>s</sub></p>
						<input value={values.mu2} className={styles.input} onChange={event=>{setValues({...values, mu2: event.target.value})}}></input>
						<p className={styles.units}></p>
					</div>
				</div>
				<div className={styles.dropdown}>
					<p className={styles.title}>Constants</p>
					<div className={styles.holder}>
						<p className={styles.value}>g</p>
						<input value={values.g} className={styles.input} onChange={event=>{setValues({...values, g: event.target.value})}}></input>
						<p className={styles.units}>m/s<sup>2</sup></p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>a<sub>for</sub></p>
						<input value={values.afor} className={styles.inputAngle} onChange={event=>{setValues({...values, afor: event.target.value, aforangle: (setAngle("afor")+180)%360})}}></input>
						<p className={styles.unitsAngle}>m/s<sup>2</sup></p>
						<select id="afor_angle_v" onChange={event=>{setValues({...values, aforangle: (setAngle("afor")+180)%360})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input placeholder="0" id="afor_angle" className={styles.degrees} onChange={event=>{setValues({...values, aforangle: (setAngle("afor")+180)%360})}}></input>
						<select id="afor_angle_h" onChange={event=>{setValues({...values, aforangle: (setAngle("afor")+180)%360})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
				</div>
			</div>
			<ForcesCanvas 
			verticesList={objects}
			vectors={vectors}
			/>
		</div>
	);
}

