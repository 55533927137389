import React, { useEffect, useState } from "react";
import Canvas from "../../Canvas/index.js";
import styles from "../../css/catchup.module.css"
import { Link } from "react-router-dom";

function quadratic(a, b, c) {
	var result = (-1 * b + Math.sqrt(Math.pow(b, 2) - (4 * a * c))) / (2 * a);
	var result2 = (-1 * b - Math.sqrt(Math.pow(b, 2) - (4 * a * c))) / (2 * a);
	return [result, result2];
}

export default function Catchup() {
	const [values, setValues] = useState({
		car1: {
			v1: 0,
			a: 0,
		},
		car2: {
			v1: 0,
			a: 0,
		},
		d: 0,
	})

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<div className={styles.car1}>
					<p className={styles.title}>Car 1</p>
					<div className={styles.holder}>
						<p className={styles.value}>V<sub>1</sub></p>
						<input className={styles.input} onChange={event=>{setValues({...values, car1: { v1: Number(event.target.value), a: values.car1.a}})}}></input>
						<p className={styles.units}>m/s</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>a</p>
						<input className={styles.input} onChange={event=>{setValues({...values, car1: { a: Number(event.target.value), v1: values.car1.v1}})}}></input>
						<p className={styles.units}>m/s<sup>2</sup></p>
					</div>
				</div>
				<div className={styles.car2}>
					<p className={styles.title}>Car 2</p>
					<div className={styles.holder}>
						<p className={styles.value}>V<sub>1</sub></p>
						<input className={styles.input} onChange={event=>{setValues({...values, car2: { v1: Number(event.target.value), a: values.car2.a}})}}></input>
						<p className={styles.units}>m/s</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>a</p>
						<input className={styles.input} onChange={event=>{setValues({...values, car2: { a: Number(event.target.value), v1: values.car2.v1}})}}></input>
						<p className={styles.units}>m/s<sup>2</sup></p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>Δd</p>
						<input className={styles.input} onChange={event=>{setValues({...values, d: Number(event.target.value)})}}></input>
						<p className={styles.units}>m</p>
					</div>
					<div className={styles.time}>
						<p id="t1">t<sub>1</sub> = {quadratic(0.5*(values["car2"]["a"]-values["car1"]["a"]), (values["car2"]["v1"]-values["car1"]["v1"]), values["d"])[0].toFixed(3)}s</p>
						<p id="t2">t<sub>2</sub> = {quadratic(0.5*(values["car2"]["a"]-values["car1"]["a"]), (values["car2"]["v1"]-values["car1"]["v1"]), values["d"])[1].toFixed(3)}s</p>
					</div>
				</div>
			</div>
			<Canvas
				verticesList={[
					{
						type: "function",
						function: (x)=>(x*values["car1"]["v1"]) + (0.5*x*x*values["car1"]["a"]),
						color: "#dd634b",
						lineWidth: 2,
					},
					{
						type: "function",
						function: (x)=>(x*values["car2"]["v1"]) + (0.5*x*x*values["car2"]["a"]) + (values["d"]),
						color: "#4b55dd",
						lineWidth: 2,
					},
				]}
				gridVisible={true}
				axesVisible={true}
				className={styles.canvas}
			/>
		</div>
	);
}

