import React, { useEffect, useState } from "react";
import Canvas from "../../Canvas/index.js";
import styles from "../../css/catchup.module.css"
import { Link } from "react-router-dom";

function quadratic(a, b, c) {
	var result = (-1 * b + Math.sqrt(Math.pow(b, 2) - (4 * a * c))) / (2 * a);
	var result2 = (-1 * b - Math.sqrt(Math.pow(b, 2) - (4 * a * c))) / (2 * a);
	return [result, result2];
}

function setAngle(define, angle=null) {
	const vec1 = document.getElementById(define+"_angle_v").value
	const vec2 = document.getElementById(define+"_angle_h").value
	if(angle === null)
		angle = Number(document.getElementById(define+"_angle").value)

	let return_angle = 0;
	if(vec1 == "N") {
		if(vec2 == "E")
			return_angle = 90-angle;
		else
			return_angle = 90+angle;
	} else {
		if(vec2 == "E")
			return_angle = 270+angle;
		else
			return_angle = 270-angle;
	}
	if(return_angle%360 < 0) {
		return_angle = 360 + return_angle%360
	}
	return Number(return_angle%360);
}

function getAngle(angle) {
	if(angle === 0) {
		return "[E]"
	} else if(angle > 0 && angle < 90) {
		return "[N " + String((90-angle).toFixed(2)) + "° E]"
	} else if(angle === 90) {
		return "[N]"
	} else if(angle > 90 && angle < 180) {
		return "[N " + String((angle-90).toFixed(2)) + "° W]"
	} else if(angle === 180) {
		return "[W]"
	} else if(angle > 180 && angle < 270) {
		return "[S " + String((270-angle).toFixed(2)) + "° W]"
	} else if(angle === 270) {
		return "[S]"
	} else if(angle > 270 && angle < 360) {
		return "[S " + String((angle-270).toFixed(2)) + "° E]"
	}
}

export default function ProjectileMotion() {
	const [values, setValues] = useState({
		v1: 0,
		v1angle: 90,
		g: -9.81,
		h: 0,
		d: 0,
		t: 0,
	})

	const [answers, setAnswers] = useState({
		v1x: 0,
		v1y: 0,
		maxh: 0,
		vfinal: 0,
	})

	useEffect(() => {
		setAnswers({
			...answers,
			v1x: Number(values.v1)*Math.cos(values.v1angle*Math.PI/180),
			v1y: Number(values.v1)*Math.sin(values.v1angle*Math.PI/180),
			vfinaly: Number(values.v1)*Math.sin(values.v1angle*Math.PI/180) + (Number(values.g)*quadratic(0.5*(values.g), (answers.v1y), values.h)[1])
		})
	}, [values])

	const maxheight = () => {
		var x = ((quadratic(0.5*(values.g), (answers.v1y), values.h)[0]+quadratic(0.5*(values.g), (answers.v1y), values.h)[1])/2)
		return ((x*Number(answers.v1y)) + (0.5*x*x*values.g) + Number(values.h))
	}

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<div className={styles.car1}>
					<p className={styles.title}>Object</p>
					<div className={styles.holder}>
						<p className={styles.value}>V<sub>1</sub></p>
						<input value={values.v1} className={styles.input} onChange={event=>{setValues({...values, v1: event.target.value})}}></input>
						<p className={styles.units}>m/s</p>
						<select className={styles.v1_angle_v} id="v1_angle_v" onChange={event=>{setValues({...values, v1angle: setAngle("v1")})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input placeholder='0' id="v1_angle" className={styles.degrees} onChange={event=>{setValues({...values, v1angle: setAngle("v1")})}}></input>
						<select id="v1_angle_h" onChange={event=>{setValues({...values, v1angle: setAngle("v1")})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>h</p>
						<input value={values.h} className={styles.input} onChange={event=>{setValues({...values, h: event.target.value})}}></input>
						<p className={styles.units}>m</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>g</p>
						<input value={values.g} className={styles.input} onChange={event=>{setValues({...values, g: event.target.value})}}></input>
						<p className={styles.units}>m/s<sup>2</sup></p>
					</div>
				</div>
				<div className={styles.car2}>
					<div className={styles.time}>
						<p id="t1">t<sub>1</sub> = {quadratic(0.5*(values.g), (answers.v1y), values.h)[0].toFixed(3)}s</p>
						<p id="t2">t<sub>2</sub> = {quadratic(0.5*(values.g), (answers.v1y), values.h)[1].toFixed(3)}s</p>
						<p id="d">d = {(quadratic(0.5*(values.g), (answers.v1y), values.h)[1]*(Math.abs(answers.v1x))).toFixed(3)}m</p>
						<p id="mh">max height = {maxheight().toFixed(3)}m</p>
						<p id="v2">V<sub>2</sub> = {Math.sqrt(Math.pow(answers.vfinaly, 2) + Math.pow(answers.v1x, 2)).toFixed(3)}m/s</p>
					</div>
				</div>
			</div>
			<Canvas
				verticesList={[
					{
						type: "function",
						function: (x)=>(x*Number(answers.v1y)) + (0.5*x*x*values.g) + Number(values.h),
						color: "#dd634b",
						lineWidth: 2,
					},
					{
						type: "function",
						function: (x)=>((x/answers.v1x)*Number(answers.v1y)) + (0.5*(x/answers.v1x)*(x/answers.v1x)*values.g) + Number(values.h),
						color: "#4b55dd",
						lineWidth: 2,
					},
				]}
				gridVisible={true}
				axesVisible={true}
				className={styles.canvas}
			/>
		</div>
	);
}

