import styles from "../../css/unit5/collisions.module.css"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Canvas from "./canvas";

function setAngle(define, angle=null) {
	const vec1 = document.getElementById(define+"_angle_v").value
	const vec2 = document.getElementById(define+"_angle_h").value
	if(angle === null)
		angle = Number(document.getElementById(define+"_angle").value)

	let return_angle = 0;
	if(vec1 === "N") {
		if(vec2 === "E")
			return_angle = 90-angle;
		else
			return_angle = 90+angle;
	} else {
		if(vec2 === "E")
			return_angle = 270+angle;
		else
			return_angle = 270-angle;
	}
	if(return_angle%360 < 0) {
		return_angle = 360 + return_angle%360
	}
	return Number(return_angle%360);
}

export default function Unit5Ballistic() {
	const [objects, setObjects] = useState([
		{
			type: "polygon",
			polygon: [
				{
					x: -500,
					y: 0,
				},
				{
					x: 500,
					y: 0,
				},
			],
			colorStroke: "#00000050",
			colorFill: '#0000'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: 0,
					y: 500,
				},
				{
					x: 0,
					y: -500,
				},
			],
			colorStroke: "#00000030",
			colorFill: '#0000'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: 0,
					y: 1.74,
				},
				{
					x: 3,
					y: 3,
				},
				{
					x: 6,
					y: 4.286,
				},
				{
					x: 7.078,
					y: 1.78,
				},
				{
					x: 1.08,
					y: -0.806,
				},
			],
			colorStroke: "#000000",
			colorFill: '#0000'
		},
		{
			type: "vector",
			coordinates: {
				start: {
					x: 0,
					y: 10,
				},
				end: {
					x: 3,
					y: 3,
				},
			},
			color: "#000",
			lineWidth: 2,
		},
		{
			type: "vector",
			coordinates: {
				start: {
					x: -5,
					y: 0,
				},
				end: {
					x: 0,
					y: 0,
				},
			},
			color: "#000",
			lineWidth: 2,
		},
		{
			type: "text",
			text: "θ₁",
			x: 0.3,
			y: 8.6,
			size: 0.5
		},
		{
			type: "text",
			text: "m₁",
			x: -0.5,
			y: 0.5,
			size: 0.5
		},
		{
			type: "text",
			text: "m₂",
			x: 3.5,
			y: 1.75,
			size: 0.5
		},
		{
			type: "text",
			text: "L",
			x: 2,
			y: 6.5,
			size: 0.5
		},
	]);

	const [values, setValues] = useState({
		m1: 1,
		m2: 1,
		l: 1,
		v1: "",
		v2: "",
		angle: "",
		h: "",
		g: -9.81,
	})

	useEffect(() => {
			const v1 = Number(document.getElementById("v1").value)
			const v2 = (Number(v1)*(Number(values.m1)))/(Number(values.m1)+Number(values.m2))
			const h = (0.5*Number(v2)*Number(v2))/(-Number(values.g))
			const angle = Math.acos(((Number(values.l) - Math.abs(Number(h)))/Number(values.l)))*180/Math.PI

			document.getElementById("v2").value = v2.toFixed(3)
			document.getElementById("h").value = h.toFixed(3)
			document.getElementById("angle").value = angle.toFixed(3)

			if(document.getElementById("v1").value === "") {
				document.getElementById("v1").value = ""
				document.getElementById("v2").value = ""
				document.getElementById("h").value = ""
				document.getElementById("angle").value = ""
			}
	}, [values.v1])
	
	useEffect(() => {
		const v2 = Number(document.getElementById("v2").value)
		const v1 = (Number(v2)*(Number(values.m1)+Number(values.m2)))/(Number(values.m1))
		const h = (0.5*Number(v2)*Number(v2))/(-Number(values.g))
		const angle = Math.acos(((Number(values.l) - Math.abs(Number(h)))/Number(values.l)))*180/Math.PI
	
		document.getElementById("v1").value = v1.toFixed(3)
		document.getElementById("h").value = h.toFixed(3)
		document.getElementById("angle").value = angle.toFixed(3)

		if(document.getElementById("v2").value === "") {
			document.getElementById("v1").value = ""
			document.getElementById("v2").value = ""
			document.getElementById("h").value = ""
			document.getElementById("angle").value = ""
		}
	}, [values.v2])

	useEffect(() => {
		const h = Number(document.getElementById("h").value)
		const angle = Math.acos(((Number(values.l) - Math.abs(Number(h)))/Number(values.l)))*180/Math.PI
		const v2 = Math.sqrt(2*-Number(values.g)*Number(h))
		const v1 = (Number(v2)*(Number(values.m1)+Number(values.m2)))/(Number(values.m1))
	
		document.getElementById("v1").value = v1.toFixed(3)
		document.getElementById("v2").value = v2.toFixed(3)
		document.getElementById("angle").value = angle.toFixed(3)

		if(document.getElementById("h").value === "") {
			document.getElementById("v1").value = ""
			document.getElementById("v2").value = ""
			document.getElementById("h").value = ""
			document.getElementById("angle").value = ""
		}
	}, [values.h])

	useEffect(() => {
		const angle = Number(document.getElementById("angle").value)
		const h = Number(values.l)-(Number(values.l)*Math.cos(Number(angle)*Math.PI/180))
		const v2 = Math.sqrt(2*-Number(values.g)*Number(h))
		const v1 = (Number(v2)*(Number(values.m1)+Number(values.m2)))/(Number(values.m1))
	
		document.getElementById("v1").value = v1.toFixed(3)
		document.getElementById("v2").value = v2.toFixed(3)
		document.getElementById("h").value = h.toFixed(3)

		if(document.getElementById("angle").value === "") {
			document.getElementById("v1").value = ""
			document.getElementById("v2").value = ""
			document.getElementById("h").value = ""
			document.getElementById("angle").value = ""
		}
	}, [values.angle])

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<div className={styles.dropdown}>
					<p className={styles.title}>Constants</p>
					<div className={styles.holder}>
						<p className={styles.value}>m<sub>1</sub></p>
						<input value={values.m1} className={styles.input} onChange={event=>{setValues({...values, m1: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>m<sub>2</sub></p>
						<input value={values.m2} className={styles.input} onChange={event=>{setValues({...values, m2: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>l</p>
						<input value={values.l} className={styles.input} onChange={event=>{setValues({...values, l: event.target.value})}}></input>
						<p className={styles.units}>m</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>g</p>
						<input value={values.g} className={styles.input} onChange={event=>{setValues({...values, g: event.target.value})}}></input>
						<p className={styles.units}>m/s<sup>2</sup></p>
					</div>
					<p className={styles.title}>Variables</p>
					<div className={styles.holder}>
						<p className={styles.value}>v<sub>1</sub></p>
						<input id="v1" value={values.v1} className={styles.input} onChange={event=>{setValues({...values, v1: event.target.value})}}></input>
						<p className={styles.units}>m/s</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>v<sub>2</sub></p>
						<input id="v2" value={values.v2} className={styles.input} onChange={event=>{setValues({...values, v2: event.target.value})}}></input>
						<p className={styles.units}>m/s</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>angle</p>
						<input id="angle" value={values.angle} className={styles.input} onChange={event=>{setValues({...values, angle: event.target.value})}}></input>
						<p className={styles.units}>°</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>h</p>
						<input id="h" value={values.h} className={styles.input} onChange={event=>{setValues({...values, h: event.target.value})}}></input>
						<p className={styles.units}>m</p>
					</div>
					<button onClick={() => {
						document.getElementById("v1").value = ""
						document.getElementById("v2").value = ""
						document.getElementById("h").value = ""
						document.getElementById("angle").value = ""
					}}>Clear Values</button>
				</div>
			</div>
			<Canvas 
			verticesList={objects}
			// vectors={vectors}
			/>
		</div>
	);
}

