import React, { useEffect, useState } from "react";
import styles from "../../css/unit2/simulator.module.css"
import { Link, useNavigate } from "react-router-dom";
import ForcesCanvas from "./ForcesCanvas";

// normalText0123₀₁₂₃₄₅₆₇₈₉

export default function Unit4VelocitySpring() {
	const navigate = useNavigate();
	var example = "velocityspring"
	const [objects] = useState([
		{
			type: "polygon",
			polygon: [
				{
					x: -0.5,
					y: -1.5,
				},
				{
					x: 0.5,
					y: -1.25,
				},
				{
					x: -0.5,
					y: -1,
				},
				{
					x: 0.5,
					y: -0.75,
				},
				{
					x: -0.5,
					y: -0.5,
				},
			],
			colorStroke: "#000000",
			colorFill: '#0000',
			completePath: false,
			lineWidth: 4,
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -1,
					y: -1.5,
				},
				{
					x: 1,
					y: -1.5,
				},
				{
					x: 1,
					y: -3,
				},
				{
					x: -1,
					y: -3,
				}
			],
			colorStroke: "#000000",
			colorFill: '#cafcc7'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -1,
					y: 0,
				},
				{
					x: 1,
					y: 0,
				},
				{
					x: 1,
					y: -0.5,
				},
				{
					x: -1,
					y: -0.5,
				}
			],
			colorStroke: "#000000",
			colorFill: '#e8aa78'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -1,
					y: 0,
				},
				{
					x: 1,
					y: 0,
				},
				{
					x: 1,
					y: 2,
				},
				{
					x: -1,
					y: 2,
				}
			],
			colorStroke: "#000000",
			colorFill: '#0000'
		},
		{
			type: "vector",
			coordinates: {
				start: {
					x: 0,
					y: 2,
				},
				end: {
					x: 0,
					y: 6,
				},
			},
			color: "#000",
			lineWidth: 2,
		},
		{
			type: "text",
			text: "m",
			x: 0,
			y: 1,
			size: 0.5
		},
		{
			type: "text",
			text: "v",
			x: 0.35,
			y: 4,
			size: 0.5
		},
	]);

	const [values, setValues] = useState({
		m: "1",
		v: "",
		k: "",
		x: "",
	})

	useEffect(() => {
		var y = 0;
		for(var x of Object.keys(values)) {
			if(values[x] !== "") {
				y++;
			}
		}
		
		document.getElementById("m").placeholder = ""
		document.getElementById("v").placeholder = ""
		document.getElementById("k").placeholder = ""
		document.getElementById("x").placeholder = ""

		if(y === 3) {
			if(values.m === "") {
				document.getElementById("m").placeholder = ((values.k*values.x*values.x)/(values.v*values.v))
			} else if(values.v === "") {
				document.getElementById("v").placeholder = Math.sqrt(Math.abs((values.k*values.x*values.x)/values.m)).toFixed(2)
			} else if(values.k === "") {
				document.getElementById("k").placeholder = ((values.m*values.v*values.v)/(values.x*values.x))
			} else if(values.x === "") {
				document.getElementById("x").placeholder = Math.sqrt(Math.abs(((values.m*values.v*values.v)/values.k))).toFixed(2)
			}
		}
	}, [values])

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<select value={example} className={styles.exampleSelector} onChange={event=>{return navigate("/unit4/simulator/" + event.target.value, { replace: true})}}>
					<option value="sliding">Sliding</option>
					<option value="thrown">Thrown</option>
					<option value="springup">Spring Straight Up</option>
					{/* <option value="springramp">Spring with Ramp</option> */}
					<option value="velocityspring">Velocity from Spring</option>
					{/* <option value="harmonicspring">Simple Harmonic Oscillation</option> */}
				</select>
				<div className={styles.dropdown}>
					<p className={styles.title}></p>
					<div className={styles.holder}>
						<p className={styles.value}>m</p>
						<input id="m" value={values.m} className={styles.input} onChange={event=>{setValues({...values, m: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>v</p>
						<input id="v" value={values.v} className={styles.input} onChange={event=>{setValues({...values, v: event.target.value})}}></input>
						<p className={styles.units}>m/s</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>k</p>
						<input id="k" value={values.k} className={styles.input} onChange={event=>{setValues({...values, k: event.target.value})}}></input>
						<p className={styles.units}>N/m</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>x</p>
						<input id="x" value={values.x} className={styles.input} onChange={event=>{setValues({...values, x: event.target.value})}}></input>
						<p className={styles.units}>m</p>
					</div>
				</div>				
			</div>
			<ForcesCanvas 
			verticesList={objects}
			/>
		</div>
	);
}