import React, { useEffect, useState } from "react";
import styles from "../../css/unit3/bankedturn.module.css"
import { Link } from "react-router-dom";
import diagram from "./bankedturn.png";

export default function Unit3BankedTurn() {
	const [values, setValues] = useState({
		v: "",
		videal: "",
		r: "",
		m: "",
		g: "9.81",
		angle: "",
		mu: "",
		friction_direction: "up",
	})
	
	useEffect(() => {
		let va = {
			v: null, 
			videal: null, 
			r: null, 
			g: null, 
			angle: null, 
			mu: null, 
			friction_direction: null, 
		}

		let amount_of_vals = 0

		for(const v of ["videal", "r", "g", "angle"]) {
			if(values[v] !== "") {
				va[v] = Number(values[v])
				amount_of_vals++;
			}
		}

		if(amount_of_vals === 3) {
			if(va.videal === null) {
				document.getElementById("videal").setAttribute("placeholder", (Math.sqrt(va.g*va.r*Math.tan(va.angle*Math.PI/180))).toFixed(2))
			} else if(va.r === null) {
				document.getElementById("r").setAttribute("placeholder", ((va.videal*va.videal)/(va.g*Math.tan(va.angle*Math.PI/180))).toFixed(2))
			} else if(va.angle === null) {
				document.getElementById("angle").setAttribute("placeholder", (Math.atan((va.videal*va.videal)/(va.g*va.r))*180/Math.PI).toFixed(0))
			} else if(va.g === null) {
				document.getElementById("g").setAttribute("placeholder", ((va.videal*va.videal)/(va.r*Math.tan(va.angle*Math.PI/180))).toFixed(2))
			}
		} else {
			for(const v of ["videal", "r", "g", "angle"]) {
				if(va[v] === null) {
					document.getElementById(v).setAttribute("placeholder", "")
				}
			}
		}

		amount_of_vals = 0

		for(const v of ["v", "mu", "friction_direction"]) {
			if(values[v] !== "") {
				va[v] = Number(values[v])
				if(v === "friction_direction") {
					va[v] = values[v];
				}
				amount_of_vals++;
			}
		}

		if(amount_of_vals === 2) {
			if(va.v === null) {
				if(va.friction_direction === "up") {
					document.getElementById("v").setAttribute("placeholder", (Math.sqrt(va.g*va.r*((Math.sin(va.angle*Math.PI/180)-(va.mu*Math.cos(va.angle*Math.PI/180)))/(Math.cos(va.angle*Math.PI/180)+(va.mu*Math.sin(va.angle*Math.PI/180))))).toFixed(2)))
				} else {
					document.getElementById("v").setAttribute("placeholder", (Math.sqrt(va.g*va.r*((Math.sin(va.angle*Math.PI/180)+(va.mu*Math.cos(va.angle*Math.PI/180)))/(Math.cos(va.angle*Math.PI/180)-(va.mu*Math.sin(va.angle*Math.PI/180))))).toFixed(2)))
				}
			}
		} else {
			for(const v of ["v", "mu", "friction_direction"]) {
				if(va[v] === null) {
					document.getElementById(v).setAttribute("placeholder", "")
				}
			}
		}

	}, [values])

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<div className={styles.person}>
					<p className={styles.title}>Object</p>
					<div className={styles.holder}>
						<p className={styles.value}>V<sub>ideal</sub></p>
						<input id="videal" className={styles.input} onChange={event=>{setValues({...values, videal: event.target.value})}}></input>
						<p className={styles.units}>m/s</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>r</p>
						<input id="r" className={styles.input} onChange={event=>{setValues({...values, r: event.target.value})}}></input>
						<p className={styles.units}>m</p>
					</div>
					{/* <div className={styles.holder}>
						<p className={styles.value}>m</p>
						<input className={styles.input} onChange={event=>{setValues({...values, m: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div> */}
					<div className={styles.holder}>
						<p className={styles.value}>g</p>
						<input id="g" value={values.g} className={styles.input} onChange={event=>{setValues({...values, g: event.target.value})}}></input>
						<p className={styles.units}>m/s<sup>2</sup></p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>angle</p>
						<input id="angle" className={styles.input} onChange={event=>{setValues({...values, angle: event.target.value})}}></input>
						<p className={styles.units}>°</p>
					</div>
				</div>
				<div className={styles.person}>
					<p className={styles.title}>Friction</p>
					<div className={styles.holder}>
						<p className={styles.value}>V</p>
						<input id="v" className={styles.input} onChange={event=>{setValues({...values, v: event.target.value})}}></input>
						<p className={styles.units}>m/s</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>μ</p>
						<input id="mu" className={styles.input} onChange={event=>{setValues({...values, mu: event.target.value})}}></input>
						<p className={styles.units}></p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>Friction Direction</p>
						<select id="friction_direction" value={values.friction_direction} onChange={event=>{setValues({...values, friction_direction: event.target.value})}}>
							<option value="up">Up</option>
							<option value="down">Down</option>
						</select>
					</div>
				</div>
			</div>
			<img src={diagram} alt="diagram"></img>
		</div>
	);
}

