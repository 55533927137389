import React, { useEffect, useState } from "react";
import styles from "../../css/unit2/simulator.module.css"
import { Link, useNavigate } from "react-router-dom";
import ForcesCanvas from "./ForcesCanvas";

// normalText0123₀₁₂₃₄₅₆₇₈₉

export default function Unit4Sliding() {
	const navigate = useNavigate();
	var example = "sliding"
	const [objects] = useState([
		{
			type: "polygon",
			polygon: [
				{
					x: -100,
					y: 0,
				},
				{
					x: 100,
					y: 0,
				},
				{
					x: 100,
					y: -3,
				},
				{
					x: -100,
					y: -3,
				}
			],
			colorStroke: "#000000",
			colorFill: '#cafcc7'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -1,
					y: 0,
				},
				{
					x: 1,
					y: 0,
				},
				{
					x: 1,
					y: 2,
				},
				{
					x: -1,
					y: 2,
				}
			],
			colorStroke: "#000000",
			colorFill: '#0000'
		},
		{
			type: "vector",
			coordinates: {
				start: {
					x: -1,
					y: 1,
				},
				end: {
					x: -5,
					y: 1,
				},
			},
			color: "#000",
			lineWidth: 2,
		},
		{
			type: "text",
			text: "m",
			x: 0,
			y: 1,
			size: 0.5
		},
		{
			type: "text",
			text: "v1",
			x: -5,
			y: 1.5,
			size: 0.5
		},
	]);

	const [values, setValues] = useState({
		m: "1",
		v: "",
		angle: "0",
		mu: "",
		d: "",
		g: "-9.81",
	})

	useEffect(() => {
		var y = 0;
		for(var x of Object.keys(values)) {
			if(values[x] !== "") {
				y++;
			}
		}
		
		document.getElementById("v").placeholder = ""
		document.getElementById("angle").placeholder = ""
		document.getElementById("mu").placeholder = ""
		document.getElementById("d").placeholder = ""

		if(y === 5) {
			if(values.v === "") {
				document.getElementById("v").placeholder = Math.sqrt(Math.abs(2*values.g*(Math.sin(values.angle*Math.PI/180)-(values.mu*Math.cos(values.angle*Math.PI/180)))*values.d)).toFixed(2)
			} else if(values.mu === "") {
				document.getElementById("mu").placeholder = ((((values.v*values.v)/(2*values.g*values.d))-Math.sin(values.angle*Math.PI/180))/-Math.cos(values.angle*Math.PI/180)).toFixed(2)
			} else if(values.d === "") {
				document.getElementById("d").placeholder = ((0.5*values.v*values.v)/(values.g*((Math.sin(values.angle*Math.PI/180))-(values.mu*Math.cos(values.angle*Math.PI/180))))).toFixed(2)
			}
		}
	}, [values])

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<select value={example} className={styles.exampleSelector} onChange={event=>{return navigate("/unit4/simulator/" + event.target.value, { replace: true})}}>
					<option value="sliding">Sliding</option>
					<option value="thrown">Thrown</option>
					<option value="springup">Spring Straight Up</option>
					{/* <option value="springramp">Spring with Ramp</option> */}
					<option value="velocityspring">Velocity from Spring</option>
					{/* <option value="harmonicspring">Simple Harmonic Oscillation</option> */}
				</select>
				<div className={styles.dropdown}>
					<p className={styles.title}>Object</p>
					<div className={styles.holder}>
						<p className={styles.value}>m</p>
						<input value={values.m} className={styles.input} onChange={event=>{setValues({...values, m: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>v</p>
						<input id="v" value={values.v} className={styles.input} onChange={event=>{setValues({...values, v: event.target.value})}}></input>
						<p className={styles.units}>m/s</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>μ</p>
						<input id="mu" value={values.mu} className={styles.input} onChange={event=>{setValues({...values, mu: event.target.value})}}></input>
						<p className={styles.units}></p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>d</p>
						<input id="d" value={values.d} className={styles.input} onChange={event=>{setValues({...values, d: event.target.value})}}></input>
						<p className={styles.units}>m</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>ramp angle</p>
						<input id="angle" value={values.angle} className={styles.input} onChange={event=>{setValues({...values, angle: event.target.value})}}></input>
						<p className={styles.units}>°</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>g</p>
						<input value={values.g} className={styles.input} onChange={event=>{setValues({...values, g: event.target.value})}}></input>
						<p className={styles.units}>m/s<sup>2</sup></p>
					</div>
				</div>				
			</div>
			<ForcesCanvas 
			verticesList={objects}
			/>
		</div>
	);
}