import React, { useEffect, useState } from "react";
import styles from "../../css/unit3/bankedturn.module.css"
import { Link } from "react-router-dom";
import diagram from "./centrifugal.png";

export default function Unit3Centrifugal() {
	const [values, setValues] = useState({
		mstop: "",
		mmass: "",
		ft: "",
		r: "",
		f: "",
		g: "9.81",
	})

	const [answers, setAnswers] = useState({
		ac: 0,
	})
	
	useEffect(() => {
		let va = {
			mstop: null,
			mmass: null,
			ft: null,
			r: null,
			f: null,
		}

		let amount_of_vals = 0

		for(const v of ["mstop", "mmass", "ft", "r", "f", "g"]) {
			if(values[v] !== "") {
				va[v] = Number(values[v])
				amount_of_vals++;
			}
		}

		if(amount_of_vals === 4) {
			if(va.ft === null) {
				if(va.mmass === null) {
					va.ft = (va.mstop*4*Math.pow(Math.PI, 2)*va.r*Math.pow(va.f, 2))
					document.getElementById("ft").setAttribute("placeholder", va.ft.toFixed(3))
				} else {
					va.ft = (va.mmass*va.g)
					document.getElementById("ft").setAttribute("placeholder", va.ft.toFixed(3))
				}
			}
			if(va.mmass === null) {
				if(va.ft === null) {
					document.getElementById("mm").setAttribute("placeholder", (((va.mstop*4*Math.pow(Math.PI, 2)*va.r*Math.pow(va.f, 2)))/va.g).toFixed(3))
				} else {
					document.getElementById("mm").setAttribute("placeholder", (va.ft/va.g).toFixed(3))
				}
			}
			if(va.r === null) {
				if(va.ft === null) {
					document.getElementById("r").setAttribute("placeholder", ((va.mmass*va.g)/(va.mstop*4*Math.pow(Math.PI, 2)*Math.pow(va.f, 2))).toFixed(3))
				} else {
					document.getElementById("r").setAttribute("placeholder", (va.ft/(va.mstop*4*Math.pow(Math.PI, 2)*Math.pow(va.f, 2))).toFixed(3))
				}
			}
			if(va.f === null) {
				if(va.ft === null) {
					document.getElementById("f").setAttribute("placeholder", Math.sqrt((va.mmass*va.g)/(va.mstop*4*Math.pow(Math.PI, 2)*va.r)).toFixed(3))
				} else {
					document.getElementById("f").setAttribute("placeholder", Math.sqrt((va.ft)/(va.mstop*4*Math.pow(Math.PI, 2)*va.r)).toFixed(3))
				}
			}
			if(va.mstop === null) {
				if(va.ft === null) {
					va.mstop = ((va.mmass*va.g)/(va.r*4*Math.pow(Math.PI, 2)*Math.pow(va.f, 2)))
					document.getElementById("ms").setAttribute("placeholder", va.mstop.toFixed(3))
				} else {
					va.mstop = (va.ft/(va.r*4*Math.pow(Math.PI, 2)*Math.pow(va.f, 2)))
					document.getElementById("ms").setAttribute("placeholder", va.mstop.toFixed(3))
				}
			}

			setAnswers({ac: (va.ft/va.mstop).toFixed(3)})
		} else {
			for(const v of ["ms", "mm", "ft", "r", "f", "g"]) {
				document.getElementById(v).setAttribute("placeholder", "")
			}
		}
	}, [values])

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<div className={styles.person}>
					<p className={styles.title}>System</p>
					<div className={styles.holder}>
						<p className={styles.value}>m<sub>stop</sub></p>
						<input id="ms" className={styles.input} onChange={event=>{setValues({...values, mstop: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>m<sub>mass</sub></p>
						<input id="mm" className={styles.input} onChange={event=>{setValues({...values, mmass: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>F<sub>T</sub></p>
						<input id="ft" className={styles.input} onChange={event=>{setValues({...values, ft: event.target.value})}}></input>
						<p className={styles.units}>N</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>r</p>
						<input id="r" className={styles.input} onChange={event=>{setValues({...values, r: event.target.value})}}></input>
						<p className={styles.units}>m</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>f</p>
						<input id="f" className={styles.input} onChange={event=>{setValues({...values, f: event.target.value})}}></input>
						<p className={styles.units}>Hz</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>g</p>
						<input id="g" value={values.g} className={styles.input} onChange={event=>{setValues({...values, g: event.target.value})}}></input>
						<p className={styles.units}>m/s<sup>2</sup></p>
					</div>
				</div>
				<div style={{textAlign: "center"}}>
					<div className={styles.time}>
						<p id="ac">a<sub>for</sub> = {answers.ac}m/s<sup>2</sup></p>
					</div>
				</div>
			</div>
			<img src={diagram} alt="diagram"></img>
		</div>
	);
}

