import styles from "../../css/unit5/collisions.module.css"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Canvas from "./canvas";

function setAngle(define, angle=null) {
	const vec1 = document.getElementById(define+"_angle_v").value
	const vec2 = document.getElementById(define+"_angle_h").value
	if(angle === null)
		angle = Number(document.getElementById(define+"_angle").value)

	let return_angle = 0;
	if(vec1 === "N") {
		if(vec2 === "E")
			return_angle = 90-angle;
		else
			return_angle = 90+angle;
	} else {
		if(vec2 === "E")
			return_angle = 270+angle;
		else
			return_angle = 270-angle;
	}
	if(return_angle%360 < 0) {
		return_angle = 360 + return_angle%360
	}
	return Number(return_angle%360);
}

function setInputAngle(define, angle=null) {
	if(angle < 0) {
		angle += 360
	}
	var vec1 = document.getElementById(define+"_angle_v")
	var vec2 = document.getElementById(define+"_angle_h")

	if(angle >= 0 && angle <= 180) {
		vec1.value = "N"
	} else {
		vec1.value = "S"
		angle -= 180
	}

	if((angle >= 0 && angle <= 90) || (angle >= 270 && angle <= 360)) {
		vec2.value = "E"
		angle = 90-angle
	} else {
		vec2.value = "W"
		angle = angle-90
	}
	document.getElementById(define+"_angle").setAttribute("placeholder", angle)
}

export default function Unit5Momentum() {
	const [objects] = useState([
		{
			type: "polygon",
			polygon: [
				{
					x: -500,
					y: 0,
				},
				{
					x: 500,
					y: 0,
				},
			],
			colorStroke: "#00000050",
			colorFill: '#0000'
		},
		{
			type: "vector",
			coordinates: {
				start: {
					x: -7,
					y: 0,
				},
				end: {
					x: 0,
					y: 0,
				},
			},
			color: "#000",
			lineWidth: 2,
		},
		{
			type: "vector",
			coordinates: {
				start: {
					x: 0,
					y: 0,
				},
				end: {
					x: 7,
					y: 5,
				},
			},
			color: "#000",
			lineWidth: 2,
		},
		{
			type: "vector",
			coordinates: {
				start: {
					x: 0,
					y: 0,
				},
				end: {
					x: 7,
					y: -5,
				},
			},
			color: "#000",
			lineWidth: 2,
		},
		{
			type: "text",
			text: "θ₂",
			x: 1.3,
			y: -0.4,
			size: 0.5
		},
		{
			type: "text",
			text: "θ₁",
			x: 1.3,
			y: 0.4,
			size: 0.5
		},
		{
			type: "text",
			text: "mT",
			x: -0.3,
			y: 0.4,
			size: 0.5
		},
		{
			type: "text",
			text: "m₁",
			x: 7,
			y: 5.5,
			size: 0.5
		},
		{
			type: "text",
			text: "m₂",
			x: 7,
			y: -4.5,
			size: 0.5
		},
	]);

	const [values, setValues] = useState({
		mt: 2,
		m1: 1,
		m2: 1,
		vt: 0,
		v1: 0,
		v2: 0,
		vtangle: 90,
		v1angle: 90,
		v2angle: 90,
	})

	useEffect(() => {
		if(document.getElementById("v1_value").value === "") {
			const v2 = Number(values.v2)
			const vt = Number(values.vt)
			const v2x = Number(v2)*Math.cos((values.v2angle)*Math.PI/180)*Number(values.m2)
			const v2y = Number(v2)*Math.sin((values.v2angle)*Math.PI/180)*Number(values.m2)
			const vtx = Number(vt)*Math.cos((values.vtangle)*Math.PI/180)*Number(values.mt)
			const vty = Number(vt)*Math.sin((values.vtangle)*Math.PI/180)*Number(values.mt)
			
			const v1x = vtx-v2x;
			const v1y = vty-v2y;


			document.getElementById("v1_value").setAttribute("placeholder", (Math.sqrt(Math.pow(v1x, 2)+Math.pow(v1y, 2))/values.m1).toFixed(3))
			setInputAngle("v1", (Math.atan2(v1y, v1x))*180/Math.PI);
		} else if(document.getElementById("v2_value").value === "") {
			const v1 = Number(values.v1)
			const vt = Number(values.vt)
			const v1x = Number(v1)*Math.cos((values.v1angle)*Math.PI/180)*Number(values.m1)
			const v1y = Number(v1)*Math.sin((values.v1angle)*Math.PI/180)*Number(values.m1)
			const vtx = Number(vt)*Math.cos((values.vtangle)*Math.PI/180)*Number(values.mt)
			const vty = Number(vt)*Math.sin((values.vtangle)*Math.PI/180)*Number(values.mt)
			
			const v2x = vtx-v1x;
			const v2y = vty-v1y;

			document.getElementById("v2_value").setAttribute("placeholder", (Math.sqrt(Math.pow(v2x, 2)+Math.pow(v2y, 2))/values.m2).toFixed(3))
			setInputAngle("v2", (Math.atan2(v2y, v2x))*180/Math.PI);
		} else if(document.getElementById("vt_value").value === "") {
			const v1 = Number(values.v1)
			const v2 = Number(values.v2)
			const v1x = Number(v1)*Math.cos((values.v1angle)*Math.PI/180)*Number(values.m1)
			const v1y = Number(v1)*Math.sin((values.v1angle)*Math.PI/180)*Number(values.m1)
			const v2x = Number(v2)*Math.cos((values.v2angle)*Math.PI/180)*Number(values.m2)
			const v2y = Number(v2)*Math.sin((values.v2angle)*Math.PI/180)*Number(values.m2)
			
			const vtx = v2x+v1x;
			const vty = v2y+v1y;

			document.getElementById("vt_value").setAttribute("placeholder", (Math.sqrt(Math.pow(vtx, 2)+Math.pow(vty, 2))/values.mt).toFixed(3))
			setInputAngle("vt", (Math.atan2(vty, vtx))*180/Math.PI);
		}
	}, [values])

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<div className={styles.dropdown}>
					<p className={styles.title}>Split Object 1</p>
					<div className={styles.holder}>
						<p className={styles.value}>m<sub>1</sub></p>
						<input value={values.m1} className={styles.input} onChange={event=>{setValues({...values, m1: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>v<sub>1</sub></p>
						<input id="v1_value" className={styles.input} onChange={event=>{setValues({...values, v1: event.target.value})}}></input>
						<p className={styles.unitsAngle}>m/s</p>
						<select id="v1_angle_v" onChange={event=>{setValues({...values, v1angle: setAngle("v1")})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input placeholder="0" id="v1_angle" className={styles.degrees} onChange={event=>{setValues({...values, v1angle: setAngle("v1")})}}></input>
						<select id="v1_angle_h" onChange={event=>{setValues({...values, v1angle: setAngle("v1")})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
					<p className={styles.title}>Split Object 2</p>
					<div className={styles.holder}>
						<p className={styles.value}>m<sub>1</sub></p>
						<input value={values.m2} className={styles.input} onChange={event=>{setValues({...values, m2: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>v<sub>2</sub></p>
						<input id="v2_value" className={styles.input} onChange={event=>{setValues({...values, v2: event.target.value})}}></input>
						<p className={styles.unitsAngle}>m/s</p>
						<select id="v2_angle_v" onChange={event=>{setValues({...values, v2angle: setAngle("v2")})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input placeholder="0" id="v2_angle" className={styles.degrees} onChange={event=>{setValues({...values, v2angle: setAngle("v2")})}}></input>
						<select id="v2_angle_h" onChange={event=>{setValues({...values, v2angle: setAngle("v2")})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
					<p className={styles.title}>Initial Object</p>
					<div className={styles.holder}>
						<p className={styles.value}>m<sub>T</sub></p>
						<input value={values.mt} className={styles.input} onChange={event=>{setValues({...values, mt: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>v<sub></sub></p>
						<input id="vt_value" className={styles.input} onChange={event=>{setValues({...values, vt: event.target.value})}}></input>
						<p className={styles.unitsAngle}>m/s</p>
						<select id="vt_angle_v" onChange={event=>{setValues({...values, vtangle: setAngle("vt")})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input placeholder="0" id="vt_angle" className={styles.degrees} onChange={event=>{setValues({...values, vtangle: setAngle("vt")})}}></input>
						<select id="vt_angle_h" onChange={event=>{setValues({...values, vtangle: setAngle("vt")})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
				</div>
			</div>
			<Canvas 
			verticesList={objects}
			// vectors={vectors}
			/>
		</div>
	);
}

