import React, { useEffect, useState } from "react";
import styles from "../../css/unit2/simulator.module.css"
import { Link } from "react-router-dom";
import ForcesCanvas from "./PlumbobCanvas";

// normalText0123₀₁₂₃₄₅₆₇₈₉

function setAngle(define, angle=null) {
	const vec1 = document.getElementById(define+"_angle_v").value
	const vec2 = document.getElementById(define+"_angle_h").value
	if(angle === null)
		angle = Number(document.getElementById(define+"_angle").value)

	let return_angle = 0;
	if(vec1 === "N") {
		if(vec2 === "E")
			return_angle = 90-angle;
		else
			return_angle = 90+angle;
	} else {
		if(vec2 === "E")
			return_angle = 270+angle;
		else
			return_angle = 270-angle;
	}
	if(return_angle%360 < 0) {
		return_angle = 360 + return_angle%360
	}
	return Number(return_angle%360);
}

export default function Unit2Plumbob() {
	const [objects, setObjects] = useState([
		{
			type: "polygon",
			polygon: [
				{
					x: 0,
					y: 1000,
				},
				{
					x: 0,
					y: -1000,
				},
			],
			colorStroke: "#000000",
			colorFill: '#cafcc7',
			completePath: false,
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -3,
					y: 2,
				},
				{
					x: -2,
					y: 3,
				},
				{
					x: -3,
					y: 4,
				},
				{
					x: -4,
					y: 3,
				}
			],
			colorStroke: "#000000",
			colorFill: '#0000'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -5,
					y: 8,
				},
				{
					x: 5,
					y: 8,
				},
				{
					x: 5,
					y: 0,
				},
				{
					x: -5,
					y: 0,
				}
			],
			colorStroke: "#000000",
			colorFill: '#0000'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -2.5,
					y: 3.5,
				},
				{
					x: -0.1,
					y: 5.89,
				},
				{
					x: -0.05,
					y: 5.92,
				},
				{
					x: 0,
					y: 5.93,
				},
			],
			colorStroke: "#000000",
			colorFill: '#0000',
			completePath: false,
		},
		{
			type: "point",
			coordinates: [
				{
					x: 0,
					y: 5.76,
				},
			],
			color: "#000000",
		},
		{
			type: "text",
			text: "θ",
			x: -0.3,
			y: 5,
			size: 0.5
		},
		{
			type: "text",
			text: "m",
			x: -3,
			y: 3,
			size: 0.5
		},
	]);

	const [values, setValues] = useState({
		m: "1",
		afor: "",
		ft: "",
		angle: "",
		g: "-9.81",
	})

	useEffect(() => {
		var y = 0;
		for(var x of Object.keys(values)) {
			if(values[x] !== "") {
				y++;
			}
		}
		
		document.getElementById("afor").placeholder = ""
		document.getElementById("angle").placeholder = ""
		document.getElementById("ft").placeholder = ""
		if(y >= 3) {
			let afor = values.afor;
			let angle = values.angle;
			let ft = values.ft;
			if(values.afor === "") {
				if(values.angle === "") {
					angle = Math.atan2(values.g, Math.sqrt(Math.pow(ft, 2)-(Math.pow(values.m*values.g, 2))))*180/Math.PI+90;
				}
				afor = -values.g/(Math.tan((90-angle)*Math.PI/180))
				document.getElementById("afor").placeholder = afor.toFixed(2)
			} 
			if(values.angle === "") {
				angle = Math.atan2(values.g, afor)*180/Math.PI+90;
				document.getElementById("angle").placeholder = (angle).toFixed(2)
			} 
			if(values.ft === "") {
				ft = Math.sqrt(Math.pow(values.m*afor, 2)+Math.pow(values.m*values.g, 2))
				document.getElementById("ft").placeholder = (ft).toFixed(2)
			}
		}
	}, [values])

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<div className={styles.dropdown}>
					<p className={styles.title}>Left Side</p>
					<div className={styles.holder}>
						<p className={styles.value}>m</p>
						<input value={values.m} className={styles.input} onChange={event=>{setValues({...values, m: event.target.value})}}></input>
						<p className={styles.units}>kg</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>angle</p>
						<input id="angle" value={values.angle} className={styles.input} onChange={event=>{setValues({...values, angle: event.target.value})}}></input>
						<p className={styles.units}>°</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>a<sub>for</sub></p>
						<input id="afor" value={values.afor} className={styles.input} onChange={event=>{setValues({...values, afor: event.target.value})}}></input>
						<p style={{width: "25%"}} className={styles.unitsAngle}>m/s<sup>2</sup> [E]</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>F<sub>T</sub></p>
						<input id="ft" value={values.ft} className={styles.input} onChange={event=>{setValues({...values, ft: event.target.value})}}></input>
						<p style={{width: "25%"}} className={styles.unitsAngle}>N</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>g</p>
						<input value={values.g} className={styles.input} onChange={event=>{setValues({...values, g: event.target.value})}}></input>
						<p className={styles.units}>m/s<sup>2</sup></p>
					</div>
				</div>
			</div>
			<ForcesCanvas 
			verticesList={objects}
			/>
		</div>
	);
}

