import styles from "../../css/kinematics.module.css"
import { useEffect, useState } from "react";

function quadratic(a, b, c) {
	var result = (-1 * b + Math.sqrt(Math.pow(b, 2) - (4 * a * c))) / (2 * a);
	var result2 = (-1 * b - Math.sqrt(Math.pow(b, 2) - (4 * a * c))) / (2 * a);
	return [result, result2];
}

export default function Kinematics() {
	const [values, setValues] = useState({
		v1: "", 
		v2: "", 
		a: "", 
		d: "", 
		t: "",
	})

	useEffect(() => {
		let va = {
			v1: null, 
			v2: null, 
			a: null, 
			d: null, 
			t: null,
		}

		let amount_of_vals = 0

		for(const v of ["v1", "v2", "a", "d", "t"]) {
			if(values[v] !== "") {
				va[v] = Number(values[v])
				amount_of_vals++;
			}
		}

		const v1 = va["v1"]
		const v2 = va["v2"]
		const a = va["a"]
		const d = va["d"]
		const t = va["t"]

		if(amount_of_vals === 3) {
			if(va["v1"] === null && va["v2"] === null) {
				document.getElementById("v1").setAttribute("placeholder", ((d/t)-(0.5*a*t)).toFixed(4))
				document.getElementById("v2").setAttribute("placeholder", ((d/t)+(0.5*a*t)).toFixed(4))
			}
			if(va["v1"] === null && va["a"] === null) {
				document.getElementById("v1").setAttribute("placeholder", ((2*d/t)-v2).toFixed(4))
				document.getElementById("a").setAttribute("placeholder", ((2*((v2*t)-d))/(t*t)).toFixed(4))
			}
			if(va["v1"] === null && va["d"] === null) {
				document.getElementById("v1").setAttribute("placeholder", (v2-(a*t)).toFixed(4))
				document.getElementById("d").setAttribute("placeholder", ((v2*t)-(0.5*a*t*t)).toFixed(4))
			}
			if(va["v1"] === null && va["t"] === null) {
				document.getElementById("v1").setAttribute("placeholder", "±" + (Math.sqrt((v2*v2)-(2*a*d))).toFixed(4))
				document.getElementById("t").setAttribute("placeholder", (quadratic(-0.5*a, v2, -d)[0]).toFixed(4) + " or " + (quadratic(-0.5*a, v2, -d)[1]).toFixed(4))
			}
			if(va["v2"] === null && va["a"] === null) {
				document.getElementById("v2").setAttribute("placeholder", ((2*d/t)-v1).toFixed(4))
				document.getElementById("a").setAttribute("placeholder", ((d-(v1*t))/(0.5*t*t)).toFixed(4))
			}
			if(va["v2"] === null && va["d"] === null) {
				document.getElementById("v2").setAttribute("placeholder", (v1+(a*t)).toFixed(4))
				document.getElementById("d").setAttribute("placeholder", ((v1*t)+(0.5*a*t*t)).toFixed(4))
			}
			if(va["v2"] === null && va["t"] === null) {
				document.getElementById("v2").setAttribute("placeholder", "±" + (Math.sqrt((v1*v1)+(2*a*d))).toFixed(4))
				document.getElementById("t").setAttribute("placeholder", (quadratic(0.5*a, v1, -d)[0]).toFixed(4) + " or " + (quadratic(0.5*a, v1, -d)[1]).toFixed(4))
			}
			if(va["a"] === null && va["d"] === null) {
				document.getElementById("a").setAttribute("placeholder", ((v2-v1)/t).toFixed(4))
				document.getElementById("d").setAttribute("placeholder", (((v2+v1)/2)*t).toFixed(4))
			}
			if(va["a"] === null && va["t"] === null) {
				document.getElementById("a").setAttribute("placeholder", (((v2*v2)-(v1*v1))/(2*d)).toFixed(4))
				document.getElementById("t").setAttribute("placeholder", ((2*d)/(v2+v1)).toFixed(4))
			}
			if(va["d"] === null && va["t"] === null) {
				document.getElementById("d").setAttribute("placeholder", (((v2*v2)-(v1*v1))/(2*a)).toFixed(4))
				document.getElementById("t").setAttribute("placeholder", ((v2-v1)/a).toFixed(4))
			}
		} else {
			for(const v of ["v1", "v2", "a", "d", "t"]) {
				if(va[v] === null) {
					document.getElementById(v).setAttribute("placeholder", "")
				}
			}
		}
	}, [values])

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<p className={styles.title}>V<sub>1</sub></p>
				<input id="v1" className={styles.input} type="number" onChange={event=>{setValues({...values, v1: event.target.value})}}></input>
				<p className={styles.units}>m/s</p>
			</div>
			<div className={styles.menu}>
				<p className={styles.title}>V<sub>2</sub></p>
				<input id="v2" className={styles.input} type="number" onChange={event=>{setValues({...values, v2: event.target.value})}}></input>
				<p className={styles.units}>m/s</p>
			</div>
			<div className={styles.menu}>
				<p className={styles.title}>a</p>
				<input id="a" className={styles.input} type="number" onChange={event=>{setValues({...values, a: event.target.value})}}></input>
				<p className={styles.units}>m/s<sup>2</sup></p>
			</div>
			<div className={styles.menu}>
				<p className={styles.title}>Δd</p>
				<input id="d" className={styles.input} type="number" onChange={event=>{setValues({...values, d: event.target.value})}}></input>
				<p className={styles.units}>m</p>
			</div>
			<div className={styles.menu}>
				<p className={styles.title}>Δt</p>
				<input id="t" className={styles.input} type="number" onChange={event=>{setValues({...values, t: event.target.value})}}></input>
				<p className={styles.units}>s</p>
			</div>
		</div>
	);
}

