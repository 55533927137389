import React, { useEffect, useState } from "react";
import Canvas from "../../Canvas/index.js";
import styles from "../../css/catchup.module.css"
import { Link } from "react-router-dom";

function quadratic(a, b, c) {
	var result = (-1 * b + Math.sqrt(Math.pow(b, 2) - (4 * a * c))) / (2 * a);
	var result2 = (-1 * b - Math.sqrt(Math.pow(b, 2) - (4 * a * c))) / (2 * a);
	return [result, result2];
}

function setAngle(define, angle=null) {
	const vec1 = document.getElementById(define+"_angle_v").value
	const vec2 = document.getElementById(define+"_angle_h").value
	if(angle === null)
		angle = Number(document.getElementById(define+"_angle").value)

	let return_angle = 0;
	if(vec1 == "N") {
		if(vec2 == "E")
			return_angle = 90-angle;
		else
			return_angle = 90+angle;
	} else {
		if(vec2 == "E")
			return_angle = 270+angle;
		else
			return_angle = 270-angle;
	}
	if(return_angle%360 < 0) {
		return_angle = 360 + return_angle%360
	}
	return Number(return_angle%360);
}

function getAngle(angle) {
	if(angle === 0) {
		return "[E]"
	} else if(angle > 0 && angle < 90) {
		return "[N " + String((90-angle).toFixed(2)) + "° E]"
	} else if(angle === 90) {
		return "[N]"
	} else if(angle > 90 && angle < 180) {
		return "[N " + String((angle-90).toFixed(2)) + "° W]"
	} else if(angle === 180) {
		return "[W]"
	} else if(angle > 180 && angle < 270) {
		return "[S " + String((270-angle).toFixed(2)) + "° W]"
	} else if(angle === 270) {
		return "[S]"
	} else if(angle > 270 && angle < 360) {
		return "[S " + String((angle-270).toFixed(2)) + "° E]"
	}
}

export default function Unit3Launch() {
	const [values, setValues] = useState({
		v1: null,
		v1angle: 90,
		g: -9.81,
		h: 0,
		r: null,
		f: null,
		d: null,
		t: 0,
	})

	const [answers, setAnswers] = useState({
		v1x: 0,
		v1y: 0,
		maxh: 0,
		// vfinal: 0,
	})

	useEffect(() => {
		const x = ((quadratic(0.5*(values.g), (values.v1*Math.sin(values.v1angle*Math.PI/180)), values.h)[0]+quadratic(0.5*(values.g), (values.v1*Math.sin(values.v1angle*Math.PI/180)), values.h)[1])/2)
		setAnswers({
			...answers,
			v1x: Number(values.v1)*Math.cos(values.v1angle*Math.PI/180),
			v1y: Number(values.v1)*Math.sin(values.v1angle*Math.PI/180),
			maxh: ((x*Number(values.v1)*Math.sin(values.v1angle*Math.PI/180)) + (0.5*x*x*values.g))
		})

		let va = {}
		let amount_of_vals = 0;

		for(const v of ["v1", "v1angle", "d", "r", "f"]) {
			if(values[v] !== "") {
				va[v] = Number(values[v])
				amount_of_vals++;
			}
		}

		document.getElementById("v").setAttribute("placeholder", "")
		document.getElementById("d").setAttribute("placeholder", "")
		document.getElementById("r").setAttribute("placeholder", "")
		document.getElementById("f").setAttribute("placeholder", "")

		if(va["v1"]) {
			document.getElementById("d").setAttribute("placeholder", (quadratic(0.5*(values.g), (Number(values.v1)*Math.sin(values.v1angle*Math.PI/180)), values.h)[1]*(Math.abs(Number(values.v1)*Math.cos(values.v1angle*Math.PI/180)))).toFixed(3))
		} else if(va["d"]) {
			const v1 = (Number(values.d)/Math.cos(values.v1angle*Math.PI/180)*Math.sqrt(-Number(values.g)/(2*(Number(values.d)*Math.tan(values.v1angle*Math.PI/180)))))
			const x = ((quadratic(0.5*(values.g), (v1*Math.sin(values.v1angle*Math.PI/180)), values.h)[0]+quadratic(0.5*(values.g), (v1*Math.sin(values.v1angle*Math.PI/180)), values.h)[1])/2)
			document.getElementById("v").setAttribute("placeholder", (v1).toFixed(3))
			setAnswers({
				...answers,
				v1x: Number(v1)*Math.cos(values.v1angle*Math.PI/180),
				v1y: Number(v1)*Math.sin(values.v1angle*Math.PI/180),
				maxh: ((x*Number(v1)*Math.sin(values.v1angle*Math.PI/180)) + (0.5*x*x*values.g) + Number(values.h))
			})
		}

		if(va["f"] && va["r"] && !va["v1"] && !va["d"]) {
			const v1 = Math.PI*2*Number(values.f)*Number(values.r)
			const x = ((quadratic(0.5*(values.g), (v1*Math.sin(values.v1angle*Math.PI/180)), values.h)[0]+quadratic(0.5*(values.g), (v1*Math.sin(values.v1angle*Math.PI/180)), values.h)[1])/2)
			document.getElementById("v").setAttribute("placeholder", (v1).toFixed(3))
			document.getElementById("d").setAttribute("placeholder", (quadratic(0.5*(values.g), (Number(v1)*Math.sin(values.v1angle*Math.PI/180)), values.h)[1]*(Math.abs(Number(v1)*Math.cos(values.v1angle*Math.PI/180)))).toFixed(3))
			setAnswers({
				...answers,
				v1x: Number(v1)*Math.cos(values.v1angle*Math.PI/180),
				v1y: Number(v1)*Math.sin(values.v1angle*Math.PI/180),
				maxh: ((x*Number(v1)*Math.sin(values.v1angle*Math.PI/180)) + (0.5*x*x*values.g) + Number(values.h))
			})
		} else if(va["f"]) {
			const v1 = (Number(values.d)/Math.cos(values.v1angle*Math.PI/180)*Math.sqrt(-Number(values.g)/(2*(Number(values.d)*Math.tan(values.v1angle*Math.PI/180)))))
			document.getElementById("r").setAttribute("placeholder", (v1/(2*Math.PI*values.f)).toFixed(3))
		} else if(va["r"]) {
			const v1 = (Number(values.d)/Math.cos(values.v1angle*Math.PI/180)*Math.sqrt(-Number(values.g)/(2*(Number(values.d)*Math.tan(values.v1angle*Math.PI/180)))))
			document.getElementById("f").setAttribute("placeholder", (v1/(2*Math.PI*values.r)).toFixed(3))
		}

	}, [values])

	const maxheight = () => {
		var x = ((quadratic(0.5*(values.g), (values.v1), values.h)[0]+quadratic(0.5*(values.g), (values.v1), values.h)[1])/2)
		return ((x*Number(answers.v1y)) + (0.5*x*x*values.g) + Number(values.h))
	}

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<div className={styles.car1}>
					<p className={styles.title}>Object</p>
					<div className={styles.holder}>
						<p className={styles.value}>V<sub>1</sub></p>
						<input id="v" value={values.v1} className={styles.input} onChange={event=>{setValues({...values, v1: event.target.value})}}></input>
						<p className={styles.units}>m/s</p>
						<select className={styles.v1_angle_v} id="v1_angle_v" onChange={event=>{setValues({...values, v1angle: setAngle("v1")})}}>
							<option value="N">N</option>
							{/* <option value="S">S</option> */}
						</select>
						<input placeholder='0' id="v1_angle" className={styles.degrees} onChange={event=>{setValues({...values, v1angle: setAngle("v1")})}}></input>
						<select id="v1_angle_h" onChange={event=>{setValues({...values, v1angle: setAngle("v1")})}}>
							<option value="E">E</option>
							{/* <option value="W">W</option> */}
						</select>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>d</p>
						<input id="d" value={values.d} className={styles.input} onChange={event=>{setValues({...values, d: event.target.value})}}></input>
						<p className={styles.units}>m</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>r</p>
						<input id="r" value={values.r} className={styles.input} onChange={event=>{setValues({...values, r: event.target.value})}}></input>
						<p className={styles.units}>m</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>f</p>
						<input id="f" value={values.f} className={styles.input} onChange={event=>{setValues({...values, f: event.target.value})}}></input>
						<p className={styles.units}>Hz</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>g</p>
						<input value={values.g} className={styles.input} onChange={event=>{setValues({...values, g: event.target.value})}}></input>
						<p className={styles.units}>m/s<sup>2</sup></p>
					</div>
				</div>
				<div className={styles.car2}>
					<div className={styles.time}>
						<p id="t1">t<sub>1</sub> = {quadratic(0.5*(values.g), (answers.v1y), values.h)[0].toFixed(3)}s</p>
						<p id="t2">t<sub>2</sub> = {quadratic(0.5*(values.g), (answers.v1y), values.h)[1].toFixed(3)}s</p>
						<p id="t2">max height = {(answers.maxh).toFixed(3)}m</p>
					</div>
				</div>
			</div>
			<Canvas
				verticesList={[
					{
						type: "function",
						function: (x)=>(x*Number(answers.v1y)) + (0.5*x*x*values.g) + Number(values.h),
						color: "#dd634b",
						lineWidth: 2,
					},
					{
						type: "function",
						function: (x)=>((x/answers.v1x)*Number(answers.v1y)) + (0.5*(x/answers.v1x)*(x/answers.v1x)*values.g) + Number(values.h),
						color: "#4b55dd",
						lineWidth: 2,
					},
				]}
				gridVisible={true}
				axesVisible={true}
				className={styles.canvas}
			/>
		</div>
	);
}

