import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Index from './App/index';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RelativeVelocity from './App/unit1/relative-velocity';
import Kinematics from './App/unit1/kinematics';
import Catchup from './App/unit1/catchup';
import Unit2Simulator from './App/unit2/simulator';
import Unit5Collisions from './App/unit5/collisions';
import Unit3FlatTurn from './App/unit3/flatturn';
import Unit3BankedTurn from './App/unit3/bankedturn';
import ProjectileMotion from './App/unit1/projectile-motion';
import Unit3Launch from './App/unit3/launch';
import Unit5Ballistic from './App/unit5/ballistic';
import Unit5Momentum from './App/unit5/momentum';
import Unit4Sliding from './App/unit4/sliding';
import Unit4Thrown from './App/unit4/thrown';
import Unit4SpringUp from './App/unit4/springup';
import Unit2Plumbob from './App/unit2/plumbob';
import Unit3Centrifugal from './App/unit3/centrifugal';
import Unit4VelocitySpring from './App/unit4/velocityspring';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
          <Route
              path="/"
              element={
                  <>
                      <Index />
                  </>
              }
          />
          <Route 
            path="/unit1/projectile-motion" 
            element={
              <>
                <ProjectileMotion />
              </>
            }
          />
          <Route 
            path="/unit1/kinematics" 
            element={
              <>
                <Kinematics />
              </>
            }
          />
          <Route 
            path="/unit1/relative-velocity" 
            element={
              <>
                <RelativeVelocity />
              </>
            }
          />
          <Route 
            path="/unit1/catchup" 
            element={
              <>
                <Catchup />
              </>
            }
          />
          <Route 
            path="/unit2/simulator" 
            element={
              <>
                <Unit2Simulator />
              </>
            }
          />
          <Route 
            path="/unit2/plumbob" 
            element={
              <>
                <Unit2Plumbob />
              </>
            }
          />
          <Route 
            path="/unit3/flatturn" 
            element={
              <>
                <Unit3FlatTurn />
              </>
            }
          />
          <Route 
            path="/unit3/bankedturn" 
            element={
              <>
                <Unit3BankedTurn />
              </>
            }
          />
          <Route 
            path="/unit3/launch" 
            element={
              <>
                <Unit3Launch />
              </>
            }
          />
          <Route 
            path="/unit3/centrifugal" 
            element={
              <>
                <Unit3Centrifugal />
              </>
            }
          />
          <Route 
            path="/unit4/simulator" 
            element={
              <>
                <Unit4Sliding />
              </>
            }
          />
          <Route 
            path="/unit4/simulator/sliding" 
            element={
              <>
                <Unit4Sliding />
              </>
            }
          />
          <Route 
            path="/unit4/simulator/thrown" 
            element={
              <>
                <Unit4Thrown />
              </>
            }
          />
          <Route 
            path="/unit4/simulator/springup" 
            element={
              <>
                <Unit4SpringUp />
              </>
            }
          />
          <Route 
            path="/unit4/simulator/velocityspring" 
            element={
              <>
                <Unit4VelocitySpring />
              </>
            }
          />
          <Route 
            path="/unit5/collisions" 
            element={
              <>
                <Unit5Collisions />
              </>
            }
          />
          <Route 
            path="/unit5/ballistic" 
            element={
              <>
                <Unit5Ballistic />
              </>
            }
          />
          <Route 
            path="/unit5/momentum" 
            element={
              <>
                <Unit5Momentum />
              </>
            }
          />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();