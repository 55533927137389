import styles from "../css/index.module.css"
import { useState } from 'react'
import { Link } from "react-router-dom";

export default function Index() {	
	const [open, setOpen] = useState([true, true, true, true, true]);

	return (
		<div className={styles.page}>
			<div>
				<p className={styles.title} onClick={()=>{setOpen([!open[0], open[1], open[2], open[3], open[4]])}}>Unit 1: Kinematics</p>
				{open[0] && 
				<>
						<Link to="unit1/kinematics"><li className={styles.item}>Kinematic Equations</li></Link>
						<Link to="unit1/catchup"><li className={styles.item}>Quadratic-Linear</li></Link>
						<Link to="unit1/relative-velocity"><li className={styles.item}>Relative Velocity</li></Link>
						<Link to="unit1/projectile-motion"><li className={styles.item}>Projectile Motion</li></Link>
				</>
					}
			</div>
			<div>
				<p className={styles.title} onClick={()=>{setOpen([open[0], !open[1], open[2], open[3], open[4]])}}>Unit 2: Forces</p>
				{open[1] && 
					<>
						{/* <li className={styles.item}>Free-body Diagram</li> */}
						<Link to="unit2/simulator"><li className={styles.item}>Forces Simulator</li></Link>
						<Link to="unit2/plumbob"><li className={styles.item}>Plumbob Simulator</li></Link>
					</>}
			</div>
			<div>
				<p className={styles.title} onClick={()=>{setOpen([open[0], open[1], !open[2], open[3], open[4]])}}>Unit 3: Uniform Circular Motion</p>
				{open[2] && 
					<>
						<Link to="unit3/flatturn"><li className={styles.item}>Flat Turn</li></Link>
						<Link to="unit3/bankedturn"><li className={styles.item}>Banked Turn</li></Link>
						<Link to="unit3/launch"><li className={styles.item}>Launching a Spinning Mass</li></Link>
						<Link to="unit3/centrifugal"><li className={styles.item}>Centrifugal</li></Link>
					</>}
			</div>
			<div>
				<p className={styles.title} onClick={()=>{setOpen([open[0], open[1], open[2], !open[3], open[4]])}}>Unit 4: Energy</p>
				{open[3] && 
					<>
						<Link to="unit4/simulator"><li className={styles.item}>Energy Simulator</li></Link>
					</>}
			</div>
			<div>
				<p className={styles.title} onClick={()=>{setOpen([open[0], open[1], open[2], open[3], !open[4]])}}>Unit 5: Momentum & Collisions</p>
				{open[4] && 
					<>
						<Link to="unit5/collisions"><li className={styles.item}>Elastic/Inelastic Collisions</li></Link>
						<Link to="unit5/momentum"><li className={styles.item}>Momentum</li></Link>
						<Link to="unit5/ballistic"><li className={styles.item}>Ballistic Pendulum</li></Link>
					</>}
			</div>
		</div>
	);
}

