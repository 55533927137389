import React, { useEffect, useState } from "react";
import styles from "../../css/unit2/simulator.module.css"
import { Link, useNavigate } from "react-router-dom";
import ForcesCanvas from "./ForcesCanvas";

// normalText0123₀₁₂₃₄₅₆₇₈₉

function setAngle(define, angle=null) {
	const vec1 = document.getElementById(define+"_angle_v").value
	const vec2 = document.getElementById(define+"_angle_h").value
	if(angle === null)
		angle = Number(document.getElementById(define+"_angle").value)

	let return_angle = 0;
	if(vec1 === "N") {
		if(vec2 === "E")
			return_angle = 90-angle;
		else
			return_angle = 90+angle;
	} else {
		if(vec2 === "E")
			return_angle = 270+angle;
		else
			return_angle = 270-angle;
	}
	if(return_angle%360 < 0) {
		return_angle = 360 + return_angle%360
	}
	return Number(return_angle%360);
}

function setInputAngle(define, angle=null) {
	if(angle < 0) {
		angle += 360
	}
	var vec1 = document.getElementById(define+"_angle_v")
	var vec2 = document.getElementById(define+"_angle_h")

	if(angle >= 0 && angle <= 180) {
		vec1.value = "N"
	} else {
		vec1.value = "S"
		angle -= 180
	}

	if((angle >= 0 && angle <= 90) || (angle >= 270 && angle <= 360)) {
		vec2.value = "E"
		angle = 90-angle
	} else {
		vec2.value = "W"
		angle = angle-90
	}
	document.getElementById(define+"_angle").setAttribute("placeholder", angle)
}

export default function Unit4Thrown() {
	const navigate = useNavigate();
	var example = "thrown"
	const [objects] = useState([
		{
			type: "function",
			function: (x)=>((-0.185063718141*x*x) + (x*0.980509745127) + 4.70127436282),
			color: "#00000054",
			lineWidth: 2,
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -100,
					y: 0,
				},
				{
					x: -2,
					y: 0,
				},
				{
					x: -2,
					y: -4,
				},
				{
					x: 100,
					y: -4,
				},
				{
					x: 100,
					y: -30,
				},
				{
					x: -100,
					y: -30,
				}
			],
			colorStroke: "#000000",
			colorFill: '#cafcc7'
		},
		{
			type: "polygon",
			polygon: [
				{
					x: -4,
					y: 0,
				},
				{
					x: -2,
					y: 0,
				},
				{
					x: -2,
					y: 2,
				},
				{
					x: -4,
					y: 2,
				}
			],
			colorStroke: "#000000",
			colorFill: '#fff'
		},
		{
			type: "vector",
			coordinates: {
				start: {
					x: -2,
					y: 2,
				},
				end: {
					x: 0,
					y: 4,
				},
			},
			color: "#000",
			lineWidth: 2,
		},
		{
			type: "vector",
			coordinates: {
				start: {
					x: 9,
					y: 0,
				},
				end: {
					x: 10,
					y: -4,
				},
			},
			color: "#000",
			lineWidth: 2,
		},
		{
			type: "text",
			text: "m",
			x: -3,
			y: 1,
			size: 0.5
		},
		{
			type: "text",
			text: "v1",
			x: -0.5,
			y: 2.5,
			size: 0.5
		},
		{
			type: "text",
			text: "v2",
			x: 10,
			y: -2.5,
			size: 0.5
		},
	]);

	const [values, setValues] = useState({
		v1: "",
		angle1: "90",
		v2: "",
		angle2: "90",
		h: "0",
		g: "-9.81",
	})

	useEffect(() => {
		var y = 0;
		for(var x of Object.keys(values)) {
			if(values[x] !== "") {
				y++;
			}
		}

		document.getElementById("v1").placeholder = ""
		document.getElementById("v2").placeholder = ""
		document.getElementById("h").placeholder = ""

		if(y === 5) {
			if(values.v1 === "") {
				document.getElementById("v1").placeholder = Math.sqrt(Math.abs(Number(values.v2)*Number(values.v2))-Math.abs(2*Number(values.g)*Number(values.h))).toFixed(2)
			} else if(values.v2 === "") {
				document.getElementById("v2").placeholder = Math.sqrt(Math.abs(Number(values.v1)*Number(values.v1))+Math.abs(2*Number(values.g)*Number(values.h))).toFixed(2)
			} else if(values.h === "") {
				document.getElementById("h").placeholder = (((0.5*Number(values.v2)*Number(values.v2))-(0.5*Number(values.v1)*Number(values.v1)))/Number(-values.g)).toFixed(2)
			}
		}
	}, [values])

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<select value={example} className={styles.exampleSelector} onChange={event=>{return navigate("/unit4/simulator/" + event.target.value, { replace: true})}}>
					<option value="sliding">Sliding</option>
					<option value="thrown">Thrown</option>
					<option value="springup">Spring Straight Up</option>
					{/* <option value="springramp">Spring with Ramp</option> */}
					<option value="velocityspring">Velocity from Spring</option>
					{/* <option value="harmonicspring">Simple Harmonic Oscillation</option> */}
				</select>
				<div className={styles.dropdown}>
					<p className={styles.title}></p>
					{/* <div className={styles.holder}>
						<p className={styles.value}>V<sub>1</sub></p>
						<input style={{width: "15%"}} id="v1" value={values.v1} className={styles.inputAngle} onChange={event=>{setValues({...values, v1: event.target.value, angle1: setAngle("v1")})}}></input>
						<p className={styles.unitsAngle}>m/s</p>
						<select id="v1_angle_v" onChange={event=>{setValues({...values, angle1: setAngle("v1")})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input style={{width: "10%"}} placeholder="0" id="v1_angle" className={styles.degrees} onChange={event=>{setValues({...values, angle1: setAngle("v1")})}}></input>
						<select id="v1_angle_h" onChange={event=>{setValues({...values, angle1: setAngle("v1")})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>V<sub>2</sub></p>
						<input style={{width: "15%"}} id="v2" value={values.v2} className={styles.inputAngle} onChange={event=>{setValues({...values, v2: event.target.value, angle2: setAngle("v2")})}}></input>
						<p className={styles.unitsAngle}>m/s</p>
						<select id="v2_angle_v" onChange={event=>{setValues({...values, angle2: setAngle("v2")})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input style={{width: "10%"}} placeholder="0" id="v2_angle" className={styles.degrees} onChange={event=>{setValues({...values, angle2: setAngle("v2")})}}></input>
						<select id="v2_angle_h" onChange={event=>{setValues({...values, angle2: setAngle("v2")})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div> */}
					<div className={styles.holder}>
						<p className={styles.value}>v<sub>1</sub></p>
						<input id="v1" value={values.v1} className={styles.input} onChange={event=>{setValues({...values, v1: event.target.value})}}></input>
						<p className={styles.units}>m/s</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>v<sub>2</sub></p>
						<input id="v2" value={values.v2} className={styles.input} onChange={event=>{setValues({...values, v2: event.target.value})}}></input>
						<p className={styles.units}>m/s</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>h</p>
						<input id="h" value={values.h} className={styles.input} onChange={event=>{setValues({...values, h: event.target.value})}}></input>
						<p className={styles.units}>m</p>
					</div>
					<div className={styles.holder}>
						<p className={styles.value}>g</p>
						<input value={values.g} className={styles.input} onChange={event=>{setValues({...values, g: event.target.value})}}></input>
						<p className={styles.units}>m/s<sup>2</sup></p>
					</div>
				</div>
			</div>
			<ForcesCanvas 
			verticesList={objects}
			/>
		</div>
	);
}