import React, { useEffect, useState } from "react";
import styles from "../../css/relative-velocity.module.css"
import { Link } from "react-router-dom";
import diagram from "./relative-velocity.png"

function setAngle(define, angle=null) {
	const vec1 = document.getElementById(define+"_angle_v").value
	const vec2 = document.getElementById(define+"_angle_h").value
	if(angle === null)
		angle = Number(document.getElementById(define+"_angle").value)

	let return_angle = 0;
	if(vec1 === "N") {
		if(vec2 === "E")
			return_angle = 90-angle;
		else
			return_angle = 90+angle;
	} else {
		if(vec2 === "E")
			return_angle = 270+angle;
		else
			return_angle = 270-angle;
	}
	if(return_angle%360 < 0) {
		return_angle = 360 + return_angle%360
	}
	return Number(return_angle%360);
}

function getAngle(angle) {
	if(angle === 0) {
		return "[E]"
	} else if(angle > 0 && angle < 90) {
		return "[N " + String((90-angle).toFixed(2)) + "° E]"
	} else if(angle === 90) {
		return "[N]"
	} else if(angle > 90 && angle < 180) {
		return "[N " + String((angle-90).toFixed(2)) + "° W]"
	} else if(angle === 180) {
		return "[W]"
	} else if(angle > 180 && angle < 270) {
		return "[S " + String((270-angle).toFixed(2)) + "° W]"
	} else if(angle === 270) {
		return "[S]"
	} else if(angle > 270 && angle < 360) {
		return "[S " + String((angle-270).toFixed(2)) + "° E]"
	}
}

function solveV1(v1, v2, angle1, angle2) {
	const x2 = Math.round(v2*Math.cos((angle1-angle2)*Math.PI/180)*100)/100
	const y2 = Math.round(v2*Math.sin((angle1-angle2)*Math.PI/180)*100)/100

	const angleV1 = Math.asin(-y2/v1)*180/Math.PI + angle2
	
	const v1x = Math.abs((v1*Math.cos((90-angleV1)*Math.PI/180))) + x2;

	const finalVelocity = (Math.sqrt((v1x*v1x))).toFixed(2)
	return [finalVelocity, angleV1]
}

function solveVt(v1, v2, angle1, angle2) {
	const newAngle = angle1
	const x2 = Math.round(v2*Math.cos((angle2-newAngle)*Math.PI/180)*100)/100
	const y2 = Math.round(v2*Math.sin((angle2-newAngle)*Math.PI/180)*100)/100

	const v1x = v1 + x2;
	const v1y = y2;

	const finalAngle = (Math.atan(v1y/v1x)*180/Math.PI) + newAngle;
	const finalVelocity = (Math.sqrt((v1x*v1x)+(v1y*v1y))).toFixed(2)
	
	return [finalVelocity, finalAngle, v1x, v1y]
}

function velocityToString(finalVelocity, finalAngle) {
	return finalVelocity + "m/s " + getAngle(finalAngle)
}

export default function RelativeVelocity() {
	const [values, setValues] = useState({
		person: {
			v1: 0,
		},
		push: {
			v1: 0,
			angle: 90,
		},
		distance: {
			d: 0,
			angle: 90,
		},
	})

	const [answers, setAnswers] = useState({
		shortest_distance: {
			v1: null,
			vt: null,
			d: null,
			t: null,
		},
		shortest_time: {
			v1: null,
			vt: null,
			d: null,
			t: null,
		}
	})

	useEffect(() => {
		const sd_v1 = solveV1(values.person.v1, values.push.v1, values.push.angle, values.distance.angle)
		const st_vt = solveVt(values.person.v1, values.push.v1, values.distance.angle, values.push.angle)
		setAnswers({
			shortest_distance: {
				v1: values.person.v1 + "m/s " + getAngle(sd_v1[1]),
				vt: sd_v1[0] + "m/s " + getAngle(values.distance.angle),
				d: values.distance.d + "m " + getAngle(values.distance.angle),
				t: (values.distance.d/sd_v1[0]).toFixed(2) + "s",
			},
			shortest_time: {
				v1: values.person.v1 + "m/s " + getAngle(values.distance.angle),
				vt: velocityToString(st_vt[0], st_vt[1]),
				d: (st_vt[0] * (values.distance.d/st_vt[2])).toFixed(2) + "m " + getAngle(st_vt[1]),
				t: (values.distance.d/st_vt[2]).toFixed(2) + "s",
			}
		})
	}, [values])

	return (
		<div className={styles.page}>
			<div className={styles.menu}>
				<div className={styles.header}>
					<Link to="/" className={styles.home}>Home</Link>
				</div>
				<div className={styles.person}>
					<p className={styles.title}>Person</p>
					<div className={styles.holder}>
						<p className={styles.value}>V<sub>s</sub></p>
						<input className={styles.input} onChange={event=>{setValues({...values, person: { v1: Number(event.target.value)}})}}></input>
						<p className={styles.units}>m/s</p>
					</div>
				</div>
				<div className={styles.push}>
					<p className={styles.title}>Push Vector</p>
					<div className={styles.holder}>
						<p className={styles.value}>V<sub>p</sub></p>
						<input className={styles.input} onChange={event=>{setValues({...values, push: { v1: Number(event.target.value), angle: values.push.angle}})}}></input>
						<p className={styles.units}>m/s</p>
						<select id="push_angle_v" onChange={event=>{setValues({...values, push: { angle: setAngle("push"), v1: values.push.v1}})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input id="push_angle" className={styles.degrees} onChange={event=>{setValues({...values, push: { angle: setAngle("push"), v1: values.push.v1}})}}></input>
						<select id="push_angle_h" onChange={event=>{setValues({...values, push: { angle: setAngle("push"), v1: values.push.v1}})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
				</div>
				<div className={styles.push}>
					<div className={styles.holder}>
						<p className={styles.value}>d</p>
						<input className={styles.input} onChange={event=>{setValues({...values, distance: { d: Number(event.target.value), angle: values.distance.angle}})}}></input>
						<p className={styles.units}>m</p>
						<select id="dist_angle_v" onChange={()=>{setValues({...values, distance: { angle: setAngle("dist"), d: values.distance.d}})}}>
							<option value="N">N</option>
							<option value="S">S</option>
						</select>
						<input id="dist_angle" className={styles.degrees} onChange={()=>{setValues({...values, distance: { angle: setAngle("dist"), d: values.distance.d}})}}></input>
						<select id="dist_angle_h" onChange={()=>{setValues({...values, distance: { angle: setAngle("dist"), d: values.distance.d}})}}>
							<option value="E">E</option>
							<option value="W">W</option>
						</select>
					</div>
					<p>Shortest Distance:
						<br />
						V<sub>T</sub> = {answers.shortest_distance.v1}
						<br />
						V<sub>F</sub> = {answers.shortest_distance.vt}
						<br />
						Δd = {answers.shortest_distance.d}
						<br />
						Δt = {answers.shortest_distance.t}
					</p>
					<p>Shortest Time: 
						<br />
						V<sub>T</sub> = {answers.shortest_time.v1}
						<br />
						V<sub>F</sub> = {answers.shortest_time.vt}
						<br />
						Δd = {answers.shortest_time.d}
						<br />
						Δt = {answers.shortest_time.t}
					</p>
				</div>
			</div>
			<img src={diagram} alt="diagram"></img>		
		</div>
	);
}

